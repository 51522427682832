import {
  SET_LIST,
  CHANGE_HANDLER,
  SAT_PROJECTS_LIST,
  SET_INVOICE_TEMPLATES_LIST,
  SET_LOAD_INVOICE_TEMPLATES_LIST, SET_LOAD_CLIENT
} from './actions/actionTypes';

const initialState = {
  total: 0,
  list: [],
  invoiceTemplates: [],
  projectsList: [],
  hasChanges: false,
  isFetched: {}
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LIST:
      const { data, total } = payload;
      return {
        ...state,
        list: data,
        total,
        isFetched: {...state.isFetched, clients: true}
      };
    case SET_LOAD_CLIENT:
      return {
        ...state,
        isFetched: {...state.isFetched, clients: payload}
      };
    case SET_INVOICE_TEMPLATES_LIST:
      return {
        ...state,
        invoiceTemplates: payload.data,
        total: payload.total,
        isFetched: {...state.isFetched, invoiceTemplates: true}
      };
    case SET_LOAD_INVOICE_TEMPLATES_LIST:
      return {
        ...state,
        isFetched: {...state.isFetched, invoiceTemplates: payload}
      };
    case SAT_PROJECTS_LIST:
      return {
        ...state,
        projectsList: payload.data,
        total: payload.total,
        isFetched: {...state.isFetched, projectsList: true}
      };
    case CHANGE_HANDLER:
      return {
        ...state,
        hasChanges: payload.hasChanges,
      };
    default:
      return state;
  }
};
