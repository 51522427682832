import { translate } from 'src/utils';

export default [
  {
    field: 'id',
    colId: 'id',
    headerName: translate('views.timeSheets.fields.id'),
    cellRenderer: 'loadingRenderer',
    editable: false,
    minWidth: 80,
    maxWidth: 80,
    filter: 'agNumberColumnFilter',
    floatingFilterComponentParams: { suppressMenu: false, suppressFilterButton: false },
  },
  {
    field: 'date',
    colId: 'date',
    type: 'date',
  },
  {
    field: 'project',
    colId: 'projectId',
    editable: false,
    sortable: false,
    type: 'dropdown',
    filter: '',
    optionsAccessor: 'projects'
  },
  {
    field: 'task',
    colId: 'taskId',
    type: 'dropdown',
    optionsAccessor: 'tasks'
  },
  {
    field: 'person',
    colId: 'personId',
    type: 'dropdown',
    optionsAccessor: 'people'
  },
  {
    field: 'hours',
    colId: 'hours',
    type: 'number',
    filter: 'agNumberColumnFilter',
    floatingFilterComponentParams: { suppressMenu: false, suppressFilterButton: false },
  }
];
