import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog as BaseDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Slide,
  makeStyles
} from '@material-ui/core';

import styles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

const Dialog = ({ title, children, actions, onOk, onCancel, okLabel, cancelLabel, isValid, classes, ...props }) => {

  const {content} = classes

  return (
    <BaseDialog
      scroll="paper"
      fullWidth
      TransitionComponent={Transition}
      classes={classes}
      {...props}
    >
      <DialogTitle>{title}</DialogTitle>
      {children && <DialogContent className={content}>{children}</DialogContent>}
      <DialogActions>
        {actions}
        {!!onOk && (
          <Button variant="contained" type="submit" onClick={onOk} color="primary" disabled={!isValid} key="ok">
            {okLabel}
          </Button>
        )}
        {!!onCancel && (
          <Button label={cancelLabel} onClick={onCancel} key="cancel">
            {cancelLabel}
          </Button>
        )}
      </DialogActions>
    </BaseDialog>
  );
};

Dialog.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.node,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  isValid: PropTypes.bool,
  classes: PropTypes.object,
};

Dialog.defaultProps = {
  title: 'Untitled Dialog',
  children: null,
  actions: null,
  onOk: null,
  onCancel: null,
  okLabel: 'Ok',
  cancelLabel: 'Cancel',
  isValid: true,
  classes: {},
};

export default Dialog;
