import { call, put, takeLatest, select } from 'redux-saga/effects';
import apiMethods from 'src/api/apiMethods';
import { setList } from './actions';
import { CREATE, REMOVE, SAVE } from './actions/actionTypes';

const RESOURCE = 'depreciationRules';

function* saveHandler(props, { payload: { id, ...data } }) {
  if (Object.keys(data).length) {
    const { globalActions: { setLoading } } = props;
    const url = id ? `${RESOURCE}/${id}` : RESOURCE;
    const method = id ? 'update' : 'create';

    yield put(setLoading(true));

    try {
      yield call(apiMethods[method], url, {data});
      yield call(fetchList, props);
    } catch (error) {
      // TODO: show notification
      console.log(error);
    } finally {
      yield put(setLoading(false));
    }
  } else {
    const { total, list } = yield select(state => state[RESOURCE]);
    yield put(setList({ data: list.filter(item => item.id), total: total - 1 }));
  }
}

function* removeHandler(props, { payload: id }) {
  const { globalActions: { setLoading, enqueueSnackbar } } = props;

  yield put(setLoading(true));

  try {
    yield call(apiMethods.delete, `${RESOURCE}/${id}`);
    yield call(fetchList, props);

    yield put(enqueueSnackbar({
      message: 'views.depreciationRules.delete.success',
      options: { variant: 'success' }
    }))
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString()|| 'Invoice successfully deleted!' ,
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }));
  } finally {
    yield put(setLoading(false));
  }
}

function* createHandler() {
  const { total, list } = yield select(state => state[RESOURCE]);
  const newItem = list.find(item => !item.id);

  if (!newItem) {
    yield put(setList({ data: [...list, {}], total: total + 1 }));
  }
}

function* fetchList(props) {
  const { globalActions: { setLoading } } = props;

  yield put(setLoading(true));

  try {
    const response = yield call(apiMethods.get, RESOURCE);

    yield put(setList(response));
  } catch (error) {
    // TODO: show notification
  } finally {
    yield put(setLoading(false));
  }
}

export default function* depreciationRulesSagaWatcher(props) {
  yield call(fetchList, props);
  yield takeLatest(CREATE, createHandler);
  yield takeLatest(REMOVE, removeHandler, props);
  yield takeLatest(SAVE, saveHandler, props);
}
