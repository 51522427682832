import {
  DELETE_TRANSACTION, SAVE_TRANSACTION, LOAD_ITEM, SET_ITEM, SET_TRANSACTION_ITEMS , SET_INIT_ITEM_DATA
} from './actionTypes';

export const loadItem = id => ({
  type: LOAD_ITEM,
  payload: id
});

export const setItem = data => ({
  type: SET_ITEM,
  payload: data
});

export const saveTransaction = (data, callback) => ({
  type: SAVE_TRANSACTION,
  payload: { data, callback }
});

export const deleteTransaction = (id, callback) => ({
  type: DELETE_TRANSACTION,
  payload: { id, callback }
});

export const setTransactionItems = data => ({
  type: SET_TRANSACTION_ITEMS,
  payload: data
});

export const setInitItemData = () => ({
  type: SET_INIT_ITEM_DATA
})

