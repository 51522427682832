import { call, put, takeLatest, select, take, fork } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import apiMethods from 'src/api/apiMethods';
import dateFormatter from 'src/utils/dateFormatter'
import { setList, setCalculationStatus } from './actions';
import { CREATE, REMOVE, SAVE, CLOSE_PERIOD, CALCULATE_EXPENSES } from './actions/actionTypes';
import { get } from 'lodash';
import { translate } from 'src/utils';
import { initCalculationStatus } from './constants';
import { LOCATION_CHANGE } from "connected-react-router";

const RESOURCE = 'periods';

function* saveHandler(props, { payload: { id, ...data } }) {
  const { globalActions: { setLoading, enqueueSnackbar } } = props;

  if (Object.keys(data).length) {
    const url = id ? `${RESOURCE}/${id}` : RESOURCE;
    const method = id ? 'update' : 'create';

    yield put(setLoading(true));

    try {
      yield call(apiMethods[method], url, {data});

      yield put(enqueueSnackbar({ message: translate('views.periods.savePeriodSuccess', { action: method }), options: { variant: 'success' } }))

      yield call(fetchList, props);
    } catch (error) {
      yield put(enqueueSnackbar({
        message: error.toString() || translate(`views.periods.savePeriodError`),
        options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
      }))
    } finally {
      yield put(setLoading(false));
    }
  } else {
    const { total, list } = yield select(state => state[RESOURCE]);
    yield put(setList({ data: list.filter(item => item.id), total: total - 1 }));
  }
}

function* removeHandler(props, { payload: id }) {
  const { globalActions: { setLoading } } = props;

  yield put(setLoading(true));

  try {
    yield call(apiMethods.delete, `${RESOURCE}/${id}`);
    yield call(fetchList, props);
  } catch (error) {
    // TODO: show notification
    console.log(error);
  } finally {
    yield put(setLoading(false));
  }
}

function* createHandler() {
  const { total, list } = yield select(state => state[RESOURCE]);
  const newItem = list.find(item => !item.id);

  if (!newItem) {
    const date = new Date();
    const item = { active: false };
    date.setDate(1);
    item.start = dateFormatter(date);
    date.setMonth(date.getMonth() + 1);
    date.setDate(0);
    item.end = dateFormatter(date);
    yield put(setList({ data: [...list, item], total: total + 1 }));
  }
}

function* fetchList(props) {
  const { globalActions: { setLoading } } = props;

  yield put(setLoading(true));

  try {
    const response = yield call(apiMethods.get, RESOURCE);

    yield put(setList(response));
  } catch (error) {
    // TODO: show notification
  } finally {
    yield put(setLoading(false));
  }
}

function* closePeriodHandler(props) {
  const { globalActions: { enqueueSnackbar } } = props;

  try {
    yield call(apiMethods.create, 'period/close');

    yield put(enqueueSnackbar({
      message: translate('views.periods.closePeriod.success'),
      options: { variant: 'success' }
    }));

  } catch (error) {
    const message = translate(
      `views.periods.closePeriod.error`,
      { error: get(error, 'data', '') }
      );
    yield put(enqueueSnackbar({
      message: error.toString() || message,
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  }
}

let channelStatus = 'active';

function* interval(timeout) {
  let action = 100;

  return eventChannel((emitter) => {
    const iv = setInterval(() => {
      emitter(channelStatus);
    }, timeout);
    // The subscriber must return an unsubscribe function

    return () => {
      clearInterval(iv);
    };
  });
}

function* checkCalculationsStatusHandler(props) {
  const channel = yield call(interval, 5000);

  while (true) {
    yield take(channel);

    try {
      const { data } = yield call(apiMethods.get, 'calculate/tasks');

      yield put(setCalculationStatus(data));
    } catch (error) {
      const message = get(error, 'data', '');
      console.log(message)
    }
  }
}

function* calculateExpensesHandler(props) {
  const { globalActions: { enqueueSnackbar } } = props;

  try {
    yield call(apiMethods.create, 'calculate/expense');

    yield put(setCalculationStatus(initCalculationStatus));
  } catch (error) {
    const message = translate('periods.calculateExpenses.error', { error: get(error, 'data', '') });

    yield put(enqueueSnackbar({
      message: error.toString() || message,
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  }
}

export default function* periodsSagaWatcher(props) {
  yield takeLatest(LOCATION_CHANGE, () => channelStatus = END)
  yield call(fetchList, props);
  yield fork(checkCalculationsStatusHandler, props);
  yield takeLatest(CREATE, createHandler);
  yield takeLatest(REMOVE, removeHandler, props);
  yield takeLatest(SAVE, saveHandler, props);
  yield takeLatest(CLOSE_PERIOD, closePeriodHandler, props);
  yield takeLatest(CALCULATE_EXPENSES, calculateExpensesHandler, props);
}
