import { translate } from 'src/utils';

export default [
  {
    field: 'id',
    colId: 'id',
    headerName: translate('views.importMappings.fields.id'),
    cellRenderer: 'loadingRenderer',
    editable: false,
    minWidth: 80,
    maxWidth: 80,
    filter: 'agNumberColumnFilter',
    floatingFilterComponentParams: { suppressMenu: false, suppressFilterButton: false },
  },
  {
    field: 'system',
    colId: 'system',
    type: 'text'
  },
  {
    field: 'type',
    colId: 'type',
    type: 'text'
  },
  {
    field: 'externalId',
    colId: 'externalId',
    type: 'text'
  },
  {
    field: 'internalId',
    colId: 'internalId',
    type: 'text'
  }
];
