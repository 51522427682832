import {call, put, select, takeLatest} from "redux-saga/effects";
import {getOptionsDataResources} from 'src/utils/utils';
import {get, isObject} from 'lodash'
import colDef from './colDef';
import {
  LOAD_ITEM,
  DELETE_ASSET,
  SAVE_ASSET,
  ADD_DEPRECIATION,
  REMOVE_DEPRECIATION,
  REMOVE_ADD_DEPRECIATION
} from './actions/actionTypes';
import apiMethods from 'src/api/apiMethods';
import {addDepreciation, setItem} from './actions';
import {translate} from 'src/utils';

const RESOURCE = 'assetItems';

function* fetchOptionsData(props) {
  const {globalActions: {fetchDictionaries}} = props;
  const optionsResources = getOptionsDataResources(colDef);
  const resources = optionsResources.concat('projects', 'people', 'expenseCategories', 'depreciationRules')

  yield put(fetchDictionaries(resources));
}

function* deleteAssetHandler(props, {payload: {id, callback: refreshGrid}}) {
  const {globalActions: {enqueueSnackbar}} = props;

  try {
    yield call(apiMethods.delete, `${RESOURCE}/${id}`);
    yield put(enqueueSnackbar({
      message: 'Asset successfully deleted!',
      options: {variant: 'success'}
    }))

    refreshGrid();
  } catch (error) {
    yield put(enqueueSnackbar({
      message:error.toString() || 'Error occurred during asset deleting',
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  }
}

function* saveAssetHandler(props, {payload: {data, callback: refreshGrid}}) {
  const {globalActions: {enqueueSnackbar}} = props;
  const {id} = data;
  const method = id ? 'update' : 'create';
  const url = `${RESOURCE}/${id ? id : ''}`;

  try {
    yield call(apiMethods[method], url, {data});

    yield put(enqueueSnackbar({
      message: 'Asset successfully saved!',
      options: {variant: 'success'}
    }))

    refreshGrid();
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString() || 'Error occurred during asset saving',
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }));
  }
}

function* loadItemHandler(props, {payload: id}) {
  const {globalActions: {enqueueSnackbar}} = props;

  try {
    if (id) {
      const {data} = yield call(apiMethods.get, `${RESOURCE}/${id}`);

      yield put(setItem(data));
    } else {
      yield put(setItem({active: true}));
    }
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString() ,
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }));
  }
}

function* addDepreciationHandler(props) {
  const {globalActions: {enqueueSnackbar}} = props;

  try {
    yield call(apiMethods.create, `calculate/depreciation`, {});

    yield put(enqueueSnackbar({
      message: translate('views.assets.addDepreciationSuccess'),
      options: {variant: 'success'}
    }))

  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString() || translate('views.assets.addDepreciationError'),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }));
  }
}

function* removeDepreciationHandler(props) {
  const {globalActions: {enqueueSnackbar}} = props;
  const {start, end} = yield select(state => state.root.activePeriod);

  try {
    yield call(apiMethods.delete, 'transactions', {filter: {source: 'depreciation', date: {between: [start, end]}}});

    yield put(enqueueSnackbar({
      message: translate('views.assets.removeDepreciationSuccess'),
      options: {variant: 'success'}
    }))

  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString() || translate('views.assets.removeDepreciationError'),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }));
  }
}

function* removeAddDepreciationHandler(props) {
  const {globalActions: {enqueueSnackbar}} = props;
  const {start, end} = yield select(state => state.root.activePeriod);

  try {
    yield call(apiMethods.delete, 'transactions', {filter: {source: 'depreciation', date: {between: [start, end]}}});

    yield put(enqueueSnackbar({
      message: translate('views.assets.removeDepreciationSuccess'),
      options: {variant: 'success'}
    }))

    yield put(addDepreciation());

  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString() || translate('views.assets.removeDepreciationError'),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }));
  }
}

export default function* assetsSagaWatcher(props) {
  yield call(fetchOptionsData, props);

  yield takeLatest(SAVE_ASSET, saveAssetHandler, props);
  yield takeLatest(DELETE_ASSET, deleteAssetHandler, props);
  yield takeLatest(LOAD_ITEM, loadItemHandler, props);
  yield takeLatest(ADD_DEPRECIATION, addDepreciationHandler, props);
  yield takeLatest(REMOVE_DEPRECIATION, removeDepreciationHandler, props);
  yield takeLatest(REMOVE_ADD_DEPRECIATION, removeAddDepreciationHandler, props);
}
