import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {  DatePicker } from '@material-ui/pickers';
import {  TextField } from '@material-ui/core';

export const DATE_FORMAT = 'yyyy-MM-DD';

class DateField extends PureComponent {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    value: new Date()
  };

  handleChane = value => {
    const { name, onChange } = this.props;

    onChange({ target: { type: 'date',  name, value: value.format(DATE_FORMAT) } })
  }

  render() {
    const { onChange, formData, variant, ...rest } = this.props;

    return (
      <DatePicker
        ampm={false}
        autoOk
        format={DATE_FORMAT}
        onChange={this.handleChane}
        inputVariant={variant}
        {...rest}
      />
    );
  }
}

export default DateField;
