import { SET_LIST } from './actions/actionTypes';

const initialState = {
  total: 0,
  list: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LIST:
      const { data, total } = payload;
      return {
        ...state,
        list: data,
        total
      };
    default:
      return state;
  }
};
