import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from './components/GlobalStyles';
import ScrollReset from './components/ScrollReset';
import Notifier from 'src/modules/notifier';
import { AuthProvider } from './contexts/awsAuthContext';
import useSettings from './hooks/useSettings';
import { createTheme } from './theme';
import routes, { renderRoutes } from './routes';
import { history } from './store';

/* eslint-disable */
import '@ag-grid-community/core/dist/styles/ag-grid.css';
/* eslint-disable */
import '@ag-grid-community/core/dist/styles/ag-theme-balham.css';

const App = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <SnackbarProvider
          dense
          maxSnack={3}
          preventDuplicate
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <ConnectedRouter history={history}>
            <AuthProvider>
              <GlobalStyles />
              <ScrollReset />
              <Notifier />
              {renderRoutes(routes)}
            </AuthProvider>
          </ConnectedRouter>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
