import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Text from './Text';

const NumberField = props => {
  const { inputRef, onChange, prefix, decimalScale, type, ...other } = props;

  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={({ floatValue }) => {
        onChange({
          target: {
            name: props.name,
            value: floatValue
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix={prefix}
      decimalScale={decimalScale}
      {...other}
      type="string"
      customInput={Text}

  />
  );
}

NumberField.propTypes = {
  inputRef: PropTypes.func,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

NumberField.defaultProps = {
  prefix: ''
}

export default NumberField;
