import { SET_LIST, SET_CALCULATION_STATUS } from './actions/actionTypes';
import { initCalculationStatus } from './constants';

const initialState = {
  total: 0,
  list: [],
  calculationStatus: initCalculationStatus
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LIST:
      const { data, total } = payload;
      return {
        ...state,
        list: data,
        total
      };

    case SET_CALCULATION_STATUS:
      return {
        ...state,
        calculationStatus: payload
      }
    default:
      return state;
  }
};
