export default [
  {
    field: 'timestamp',
    colId: 'timestamp',
    type: 'date',
    editable: true,
    maxWidth: 155,
    sortable: true,
    tooltipField: 'timestamp'
  },
  {
    field: 'userEmail',
    colId: 'userEmail',
    type: 'string',
    maxWidth: 155,
    editable: true,
    tooltipField: 'userEmail'
  },
  {
    field: 'action',
    colId: 'action',
    maxWidth: 100,
    type: 'string',
    editable: true
  },
  {
    field: 'currentValues',
    colId: 'currentValues',
    type: 'string',
    minWidth: 250,
    editable: true,
    tooltipField: 'currentValues'
  },
  {
    field: 'previousValues',
    colId: 'previousValues',
    type: 'string',
    minWidth: 250,
    editable: true,
    tooltipField: 'previousValues'
  }
];
