import axios from 'axios';
import { get, isEmpty } from 'lodash';
import { Auth } from 'aws-amplify';

const processError = ({ response }) => {
  let message = get(response, 'data.message', '');
  const data = get(response, 'data', null);
  const status = get(response, 'status', null);
  const stack = get(response, 'data.stack', null);
  const statusText = get(response, 'statusText', null);
  const errors = get(response, 'data.errors', null);

  if (status === 401 && response.config.url === "periods"){
    console.error('periods error  ====', { response, status, statusText });
  }else if(status === 401 || status === 403) {
    Auth.signOut();
  }else {
    console.error('error  ====', { response, status, statusText, message, stack, errors});
  }
  if(statusText){
    message = `${status} - ${statusText}
     ${message}`
  }

  if(!isEmpty(errors)){
    let errorsMsh = errors.reduce((acc,m)=>`${acc}
    ${m.message}`, `` )
    message = `${message} ${errorsMsh}`
  }
  return new Error(message);
};

const HttpClient = axios.create({
  baseURL: `${process.env.REACT_APP_APIGATEWAY_URL}/api/`,
  headers: {
    'Content-Type': 'application/json'
  },
  crossDomain: true,
  responseType: 'JSON'
});

HttpClient.interceptors.response.use(
  ({ data, ...props}) => data,
  error => Promise.reject(processError(error))
);

export const setToken = token => {
  HttpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export default HttpClient;
