import { call, put, takeLatest, select } from 'redux-saga/effects';
import apiMethods from 'src/api/apiMethods';
import {
  setInvoiceTemplatesList,
  setList,
  setLoadClientList,
  setLoadInvoiceTemplatesList,
  setProjectList
} from './actions';
import {
  CREATE,
  REMOVE,
  SAVE,

  // invoice templates

  CREATE_INVOICE_TEMPLATE,
  REMOVE_INVOICE_TEMPLATE,
  SAVE_INVOICE_TEMPLATE,
  SAT_CURRENT_PROJECT_LIST,
  FETCH_CURRENT_INVOICE_TEMPLATES
} from './actions/actionTypes';

const RESOURCE = 'clients';

function* saveHandler(props, { payload: { id, ...data } }) {
  if (Object.keys(data).length) {
    const { globalActions: { enqueueSnackbar, setLoading } } = props;
    const url = id ? `${RESOURCE}/${id}` : RESOURCE;
    const method = id ? 'update' : 'create';

    yield put(setLoading(!id));
    yield put(setLoadClientList(false))

    try {
      yield call(apiMethods[method], url, {data});
      yield call(fetchList, props, !id);
      yield put(enqueueSnackbar({
        message: 'Client successfully saved!',
        options: { variant: 'success' }
      }))
    } catch (error) {
      yield put(enqueueSnackbar({
        message: error.toString() || 'Client non saved!',
        options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
      }));
    } finally {
      yield put(setLoading(false));
      yield put(setLoadClientList(true))
    }
  } else {
    const { total, list } = yield select(state => state[RESOURCE]);
    yield put(setList({ data: list.filter(item => item.id), total: total - 1 }));
  }
}

function* removeHandler(props, { payload: id }) {
  const { globalActions: { setLoading, enqueueSnackbar } } = props;

  yield put(setLoading(true));

  try {
    yield call(apiMethods.delete, `${RESOURCE}/${id}`);
    yield call(fetchList, props);
    yield put(enqueueSnackbar({
      message: 'Client successfully deleted!',
      options: { variant: 'success' }
    }))
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString() || 'Client not deleted !',
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }));
  } finally {
    yield put(setLoading(false));
  }
}

function* createHandler() {
  const { total, list } = yield select(state => state[RESOURCE]);
  const newItem = list.find(item => !item.id);

  if (!newItem) {
    yield put(setList({ data: [...list, { active: false }], total: total + 1 }));
  }
}

function* fetchList(props, isLoad = true) { 
  const { globalActions: { setLoading, enqueueSnackbar } } = props;
  yield put(setLoading(isLoad));
  try {
    const response = yield call(apiMethods.get, RESOURCE);

    yield put(setList(response));
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString() || 'Client not deleted !',
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }));
  } finally {
    yield put(setLoading(false));
  }
}

// invoice templates

function* saveInvoiceTemplateHandler(props, { payload: { id, ...data } }) {
  if (Object.keys(data).length) {
    const {globalActions: {enqueueSnackbar}} = props;
    const url = id ? `invoiceTemplates/${id}` : 'invoiceTemplates';
    const method = id ? 'update' : 'create';

    yield put(setLoadInvoiceTemplatesList(false))

    try {
      yield call(apiMethods[method], url, {data});
      yield call(fetchInvoiceTemplatesList, props, {payload: data.clientId});
      yield fetchList(props, false)
      yield put(enqueueSnackbar({
        message: 'Invoice successfully saved!',
        options: { variant: 'success' }
      }))
    } catch (error) {
      yield put(enqueueSnackbar({
        message: error.toString() || 'Invoice non saved!',
        options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
      }));

    } finally {
      yield put(setLoadInvoiceTemplatesList(true))
    }
  } else {
    const { total, rules } = yield select(state => state.billingRules);
    yield put(setInvoiceTemplatesList({ data: rules.filter(item => item.id), total: total - 1 }));
  }
}

function* removeInvoiceTemplateHandler(props, { payload: {id, clientId} }) {
  const { globalActions: { enqueueSnackbar } } = props;
  yield put(setLoadInvoiceTemplatesList(false))

  try {
    yield call(apiMethods.delete, `${'invoiceTemplates'}/${id}`);
    yield call(fetchInvoiceTemplatesList, props, {payload: clientId});
    yield call(fetchList, props, false)
    yield put(enqueueSnackbar({
      message: 'Invoice successfully deleted!',
      options: { variant: 'success' }
    }))
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString()|| 'Invoice successfully deleted!' ,
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }));
  } finally {
    yield put(setLoadInvoiceTemplatesList(true))
  }
}

function* createInvoiceTemplateHandler() {
  const { total, rules } = yield select(state => state.billingRules);
  const newItem = rules.find(item => !item.id);

  if (!newItem) {
    yield put(setInvoiceTemplatesList({ data: [...rules, { active: false }], total: total + 1 }));
  }
}

function* fetchInvoiceTemplatesList(props, { payload: id }) {
  const { globalActions: { enqueueSnackbar } } = props;

  try {
    const response = yield call(apiMethods.get, 'invoiceTemplates', { filter: { clientId:  id} });

    yield put(setInvoiceTemplatesList(response));
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString() ,
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }));

  } finally {
  }
}

function* fetchCurrentProjectsList({ payload: id }) {

  try {
    const response = yield call(apiMethods.get, 'projects', { filter: { clientId:  id} });

    yield put(setProjectList(response));
  } catch (error) {
    // TODO: show notification
  } finally {
  }
}

export default function* clientsSagaWatcher(props) {
  yield call(fetchList, props);
  yield takeLatest(CREATE, createHandler);
  yield takeLatest(REMOVE, removeHandler, props);
  yield takeLatest(SAVE, saveHandler, props);

  // invoice templates

  yield takeLatest(CREATE_INVOICE_TEMPLATE, createInvoiceTemplateHandler);
  yield takeLatest(REMOVE_INVOICE_TEMPLATE, removeInvoiceTemplateHandler, props);
  yield takeLatest(SAVE_INVOICE_TEMPLATE, saveInvoiceTemplateHandler, props);

  yield takeLatest(SAT_CURRENT_PROJECT_LIST, fetchCurrentProjectsList);
  yield takeLatest(FETCH_CURRENT_INVOICE_TEMPLATES, fetchInvoiceTemplatesList, props);

}
