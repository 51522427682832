import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch } from '@material-ui/core';

const SwitchField = props => {
  const { onChange, label, name, value, disabled } = props;

  return (
    <FormControlLabel
     control={
       <Switch
          onChange={onChange}
          name={name}
          color="primary"
          checked={value}
          disabled={disabled}
       />
     }

     label={label}
    />
  );
};

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  value: PropTypes.bool,
  name: PropTypes.string.isRequired
};

export default SwitchField;
