import { LOAD_ITEM, SET_ITEM } from './actions/actionTypes';

const initialState = {
  fetching: true,
  item: { active: true }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_ITEM:
      return {
        ...state,
        fetching: true
      };
    case SET_ITEM:
      return {
        ...state,
        fetching: false,
        item: payload
      };
    default:
      return state;
  }
};
