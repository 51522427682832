import moment from 'moment';
import { SET_ITEM, SET_TRANSACTION_ITEMS, LOAD_ITEM, SET_INIT_ITEM_DATA } from './actions/actionTypes';

const initialState = {
  transactionItems: [],
  fetching: true,
  item: {}
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_ITEM:
      return {
        ...state,
        fetching: true
      };

    case SET_ITEM:
      return {
        ...state,
        fetching: false,
        item: payload
      };

    case SET_TRANSACTION_ITEMS:
      return {
        ...state,
        transactionItems: payload,
      };

    case SET_INIT_ITEM_DATA:
      return {
        ...state,
        fetching: false,
        item: {
          source: 'Manual',
          date: moment().format(moment.HTML5_FMT.DATE)
        }
      };

    default:
      return state;
  }
};
