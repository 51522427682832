import * as at from './actionTypes';
import { setLoading } from 'src/modules/loadingManager/actions'
import { fetchDictionaries } from 'src/modules/dictionaries/actions'
import {fetchAuditlog} from 'src/modules/auditLog/actions'
import { enqueueSnackbar, closeSnackbar, removeSnackbar } from 'src/modules/notifier/actions'

const navigateTo = path => ({
  type: at.NAVIGATE_TO,
  payload: path
});

const setActivePeriod = period => ({
  type: at.SET_ACTIVE_PERIOD,
  payload: period
});

export {
  fetchAuditlog,
  navigateTo,
  setLoading,
  fetchDictionaries,
  setActivePeriod,
  enqueueSnackbar,
  closeSnackbar,
  removeSnackbar
};
