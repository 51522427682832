import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@material-ui/core';

const Empty = ({ showDivider }) => {
  return <Box>{showDivider && <Divider />}</Box>;
};

Empty.propTypes = {
  showDivider: PropTypes.bool
};

Empty.defaultProps = {
  showDivider: false
};

export default Empty;
