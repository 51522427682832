const dateFormatter = v => {
	// v is a `Date` object
	if (!(v instanceof Date) || isNaN(v)) return;
	const pad = '00';
	const yy = v.getFullYear().toString();
	const mm = (v.getMonth() + 1).toString();
	const dd = v.getDate().toString();
	return `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;
};

export default dateFormatter;
