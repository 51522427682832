import { translate } from 'src/utils';

export default [
  {
    field: 'id',
    colId: 'id',
    headerName: translate('views.periodSalaries.fields.id'),
    cellRenderer: 'loadingRenderer',
    editable: false,
    minWidth: 80,
    maxWidth: 80,
    filter: 'agNumberColumnFilter',
    floatingFilterComponentParams: { suppressMenu: false, suppressFilterButton: false },
  },
  {
    field: 'period',
    colId: 'periodId',
    type: 'dropdown',
    optionsAccessor: 'periods'
  },
  {
    field: 'person',
    colId: 'personId',
    type: 'dropdown',
    optionsAccessor: 'people'
  },
  {
    field: 'amount',
    colId: 'amount',
    type: 'number',
    filter: 'agNumberColumnFilter',
    floatingFilterComponentParams: { suppressMenu: false, suppressFilterButton: false },
  },
  {
    field: 'extraPtoAmount',
    colId: 'extraPtoAmount',
    type: 'number',
    filter: 'agNumberColumnFilter',
    floatingFilterComponentParams: { suppressMenu: false, suppressFilterButton: false },
  }
];
