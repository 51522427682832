import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Select, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { get } from 'lodash';


const SelectField = props => {
  const { disableClearable, type, name, onChange, optionsAccessor, defaultValue, options, label, value, formData, helperText, ...rest } = props;
  const dictionaries = useSelector(state => get(state, ['dictionaries', optionsAccessor],  []));
  const optionsData = (options || dictionaries).map(item => ({ ...item, value: item.key, label: item.value }))

  const handleChange = (event, newValue) => {
    onChange({ target: { value: newValue ? newValue.value : null, name, type, newValue } });
  }
  const getValue = value => optionsData.find((item) => item.value == value) || null

  return (
    <Autocomplete
      getOptionLabel={(option) => option.label}
      disablePortal
      id="combo-box-demo"
      options={optionsData}
      onChange={handleChange}
      name={name}
      value={getValue(value)}
      renderInput={(params) => <TextField name={name} {...params} {...rest} label={label} helperText={helperText} />}
      disableClearable={disableClearable}
      {...rest}
    />
  );
};

Select.propTypes = {
  options: PropTypes.array
};

Select.defaultProps = {
  options: []
};

export default SelectField;
