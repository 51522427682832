import en from 'src/locales/en';

const messages = {
  en,
  test: {}
};

let language = 'en';

export const setLocale = locale => {
  if (Object.keys(messages).includes(locale)) {
    language = locale;
  }
};

export const translate = (id, values) => {
  let result = messages[language];

  id.split('.').forEach(key => {
    if (typeof result === 'object') {
      result = result[key] || id;
    }
  });

  if (values) {
    Object.keys(values).forEach(key => {
      result = result.replace(new RegExp(`{${key}}`), values[key]);
    });
  }

  return result;
};
