export default {
  buttons: {
    logout: 'Logout',
    add: 'Add new',
    edit: 'Edit',
    done: 'Done',
    remove: 'Remove',
    bulkRemove: 'Remove all',
    save: 'Save',
    discard: 'Discard changes',
    sortAsc: 'Name A-Z',
    sortDesc: 'Name Z-A',
    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',
    close: 'Close',
    more: 'More',
    less: 'Less',
    previewInvoice: 'Preview invoice',
    sendToClient: 'Send to Client',
    active:{
      on: 'Active',
      off: 'Inactive'
    },
    visible:{
      yes: 'Visible',
      no: 'Unvisible'
    }
  },
  menu: {
    dashboard: 'Dashboard',
    people: 'People',
    projects: 'Projects',
    locations: 'Locations',
    assetItems: 'Assets',
    clients: 'Clients',
    departments: 'Departments',
    vendors: 'Vendors',
    moneyTypes: 'Money Types',
    periods: 'Periods',
    depreciationRules: 'Depreciation Rules',
    expenseCategories: 'Expense Categories',
    importMappings: 'Import Mappings',
    periodSalaries: 'Salaries',
    timeSheets: 'TimeSheets',
    import: 'Import',
    transactions: 'Transactions',
    invoices: 'Invoices',
    invoicesNew: 'Invoices'
  },
  views: {
    sorting: 'Sort by: {sorting}',
    emptyFilter: 'Reset filter...',
    filters: {
      name: 'Filter by name',
      client: 'Filter by client',
      active: 'Filter by status',
    },
    settings: {
      title: 'Settings',
      fields: {
        theme: 'Theme'
      }
    },
    dashboard: {
      title: 'Welcome to Finance core application'
    },
    projects: {
      title: 'Showing {total} projects',
      noLocation: 'No location',
      editPage: 'Project',
      tasks:{
        title: 'Tasks',
      },
      billingRules: {
        title: 'Billing rules',
        createPage: 'Add new rule',
        expirationDate: 'Expiration date',
        fields: {
          name: 'Name',
          status: 'Status',
          experationDate: 'Experation date',
          startDate: 'Start date',
          endDate: 'End date',
          totalDiscount: 'Total discount',
          amount: 'Amount',
          discountType: 'Discount type',
        }
      },
      fields: {
        name: 'Name',
        clientId: 'Client',
        departmentId: 'Department',
        projectManagerId: 'Client manager',
        billingId: 'Billing',
        locationId: 'Location',
      },
    },
    locations: {
      title: 'Showing {total} locations',
      fields: {
        name: 'Name',
        address: 'Address'
      }
    },
    people: {
      title: 'People',
      addTitle: 'Create new person',
      editTitle: 'Edit person: {fullName}',
      fields: {
        id: 'ID'
      },
    },
    assetItems: {
      addTitle: 'Create new asset',
      editTitle: 'Edit asset: {name}'
    },
    assets: {
      title: 'Assets List',
      fields: {
        id: 'ID'
      },
      addTitle: 'Create new asset',
      editTitle: 'Edit asset: {name}',
      deprecationButton: 'Depreciation',
      addButton: 'Add depreciation',
      removeButton: 'Delete depreciation',
      confirmRemoveTitle: 'Are you sure you want to remove all depreciation in the active period?',
      confirmAddTitle: 'Do you want to delete existing depreciation first?',
      removeDepreciationSuccess: 'Depreciation successfully removed',
      addDepreciationSuccess: 'Depreciation successfully added',
      addDepreciationError: 'Error occurred during calculation depreciation',
      removeDepreciationError: 'Error occurred during remove depreciation',
      message: {

      }
    },
    clients: {
      title: 'Showing {total} clients',
      client: 'Client',
      invoiceTemplates: {
        title: 'Invoice templates',
        invoicePeriod: 'Invoice period',
        poNumber: 'P. O. Number',
        lineItemFormat: {
          title: 'Line item format',
          itemDisplay: 'Item Display',
          divideIntoSections: 'Divide Into Sections',
          aggregateSameItems: 'Aggregate Same Items',
          showSectionTotals: 'Show Section Totals',
          columns: {
            item: 'Item',
            quantity: 'Quantity',
            rate: 'Rate',
            unit: 'Unit',
            discount: 'Discount',
            amount: 'Amount',
          }
        },
        name: 'Name',
        paymentTerm: 'Payment term',
        message: 'Message',
        recepients: 'Recepients',
        projects: 'Projects',
        schedule: 'Schedule'
      },
      fields: {
        name: 'Name',
        addressLine1: 'Address Line 1',
        addressLine2: 'Address Line 2',
        city: 'City',
        state: 'State',
        zip: 'Zip',
        country: 'Country',
      }
    },
    departments: {
      title: 'Showing {total} department',
      fields: {
        name: 'Name',
        departmentManagerId: 'Department Officer'
      }
    },
    moneyTypes: {
      title: 'Showing {total} money types',
      fields: {
        name: 'Name',
      }
    },
    vendors: {
      title: 'Showing {total} vendors',
      fields: {
        name: 'Name',
      }
    },
    periods: {
      title: 'Showing {total} periods',
      sortAsc: 'Start of period ASC',
      sortDesc: 'Start of period DESC',
      fields: {
        name: 'Name',
        start: 'Start of period',
        end: 'End of period',
        open: {
          on: 'Open',
          off: 'Closed'
        }
      },
      savePeriodSuccess: 'Period successfully {action}d',
      savePeriodError: 'Error occurred during save period',
      closePeriod: {
        error: 'The error was occurred during period closing: {error}',
        success: 'Period was successfully closed'
      },
      calculateExpenses: {
        error: 'The error was occurred during calculating expenses: {error}'
      }
    },
    depreciationRules: {
      title: 'Showing {total} depreciation rules',
      fields: {
        name: 'Name',
        duration: 'Duration'
      },
      delete: {
        error: '',
        success: 'Rule was successfully deleted'
      }
    },
    importMappings: {
      addTitle: 'Create import mappings',
      editTitle: 'Edit import mappings: {id}',
      fields: {
        id: 'ID',
        system: 'System',
        type: 'Type',
        externalId: 'External Id',
        internalId: 'Internal Id'
      }
    },
    periodSalaries: {
      addTitle: 'Create new salary',
      editTitle: 'Edit salary: {id}',
      confirmRemoveTitle: 'Are you sure you want to remove all salary transactions in the active period?',
      confirmAddTitle: 'Do you want to delete existing salary transactions first?',
      bulkDeleteTitle: 'Are you sure you want to remove all salaries in the active period?',
      buttons: {
        deprecation: 'Transactions',
        add: 'Add salary transactions',
        remove: 'Delete salary transactions'
      },
      fields: {
        id: 'ID',
        periodId: 'Period',
        personId: 'Person',
        amount: 'Amount',
        extraPtoAmount: 'Extra PTO Amount'
      }
    },
    timeSheets: {
      addTitle: 'Create new TimeSheet',
      editTitle: 'Edit TimeSheets: {id}',
      bulkDeleteTitle: 'Are you sure you want to remove all TimeSheets in the active period?',
      fields: {
        id: 'ID',
        taskId: 'Task',
        projectId: 'Project',
        date: 'Date',
        personId: 'Person',
        hours: 'Hours'
      }
    },
    import: {
      title: 'Import',
      timesheets: {
        success: 'TimeSheets successfully imported',
        tabLabel: 'timeSheets'
      },
      salaries: {
        success: 'Salaries successfully imported',
        tabLabel: 'salaries'
      },
      qboTransactions: {
        success: 'Transactions successfully imported',
        tabLabel: 'qbo transactions'
      },
      standardTransaction: {
        success: 'Transactions successfully imported',
        tabLabel: 'standard transactions'
      },
    },
    expenseCategories: {
      title: 'Showing {total} Expense Categories',
      fields: {
        name: 'Name'
      }
    },
    invoicesNew: {
      id: 'ID',
      addTitle: 'Create new invoice',
      editTitle: 'Edit invoice: {id}',
      clientInfo: 'Client info',
      lineItems: 'Line items',
      appearances: 'appearances',
      previewInvoicePDF: 'Preview invoice PDF',
      sendToClient: 'Send to client',
      fields: {
        id: 'ID',
        amount: 'Amount',
        invoiceId: 'invoice ID',
        clientId: 'Client',
        templateId: 'Template',
        status: 'Status',
        sentOn: 'Sent On',
        description: 'Description',
        pONumber: 'P.O. Number',
        dueDate: 'Due Date',
        message: 'Message',
        recipients: 'Recipients',
        date: 'Date',
        totalAmount: 'Total Amount',
        invoiceCalc: 'Invoice Calc',
        periodStart: 'Period Start',
        periodEnd: 'Period End',
        finalInvoice: 'Final Invoice',
        paymentTerm: 'Payment Term',
        paymentDate: 'Payment Date',
        amountPaid: 'Amount Paid',
        discount: {
          title: 'Discount',
          item: 'Discount Item',
          amount: 'Discount Amount'
        }
      }
    },
    transactions: {
      addTitle: 'Create new transaction',
      editTitle: 'Edit transaction: {id}'
    }
  }
}
