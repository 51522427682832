import { isLocalhost } from './utils/utils';

const config = {
	userPoolId: process.env.REACT_APP_USER_POOL_ID,
	region: process.env.REACT_APP_REGION,
	userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
	oauth: {
		domain: process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN,
		scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
		redirectSignIn: 'http://localhost:3000/',
		redirectSignOut: 'http://localhost:3000/',
		responseType: 'token' // or 'token', note that REFRESH token will only be generated when the responseType is code
	},
	// identityPoolId: 'us-east-1:741306378940:userpool/us-east-1_BN1RugBoO',
	// region: process.env.USER_POOL_CLIENT_ID,
	authenticationFlowType: 'USER_SRP_AUTH',
	// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
	// identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

	// REQUIRED - Amazon Cognito Region
	// OPTIONAL - Amazon Cognito Federated Identity Pool Region
	// Required only if it's different from Amazon Cognito Region
	// identityPoolRegion: 'XX-XXXX-X',

	// OPTIONAL - Amazon Cognito User Pool ID
	// userPoolId: 'XX-XXXX-X_abcd1234',

	// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
	// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
	// mandatorySignIn: false,

	// OPTIONAL - Configuration for cookie storage
	// Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
	// cookieStorage: {
	//   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
	//   domain: '.yourdomain.com',
	//   // OPTIONAL - Cookie path
	//   path: '/',
	//   // OPTIONAL - Cookie expiration in days
	//   expires: 365,
	//   // OPTIONAL - Cookie secure flag
	//   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
	//   secure: true
	// },

	// OPTIONAL - customized storage object
	// storage: new MyStorage(),

	// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
	// authenticationFlowType: 'USER_PASSWORD_AUTH',

	// OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
	// clientMetadata: { myCustomKey: 'myCustomValue' },

	// OPTIONAL - Hosted UI configuration
};

if (!isLocalhost) {
	config.oauth.redirectSignIn = `https://${process.env.REACT_APP_DOMAIN}/`;
	config.oauth.redirectSignOut = `https://${process.env.REACT_APP_DOMAIN}/`;
}

export default config;
