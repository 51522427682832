import { translate } from "src/utils";

export const IN_PROGRESS = 'In progress';
export const ERROR = 'Error';
export const SUCCESS = 'Success';
export const VALID_STATUSES = {
  IN_PROGRESS,
  ERROR,
  SUCCESS
}

export const initCalculationStatus = { status: IN_PROGRESS };

export const SORT_ASC = 'sortAsc';
export const SORT_DESC = 'sortDesc';

export const ACTIVE_ON = 'on';
export const ACTIVE_OFF = 'off';

export const SORT_OPTIONS = [
  { key: SORT_ASC, value: translate('views.periods.sortAsc') },
  { key: SORT_DESC, value: translate('views.periods.sortDesc') }
];

export const FILTER_OPTIONS = [
  { key: '', value: translate('views.emptyFilter') },
  { key: ACTIVE_OFF, value: translate('buttons.active.off') },
  { key: ACTIVE_ON, value: translate('buttons.active.on') }
];
