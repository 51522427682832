import React from 'react';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const PermissionGuard = ({children}) => {
  const {user, isPermiss} = useAuth();

  if (user) {
    if (isPermiss()) {
      return <>{children}</>;
    } else return <></>;
  } else return <>{children}</>;

};

PermissionGuard.propTypes = {
  children: PropTypes.node
};

export default PermissionGuard;
