import { call, put, takeLatest } from 'redux-saga/effects';
import colDef from './colDef';
import { getOptionsDataResources } from 'src/utils/utils';
import { LOAD_ITEM, SAVE_PERSON, DELETE_PERSON } from './actions/actionTypes';
import apiMethods from 'src/api/apiMethods';
import { setItem, setPeopleLoading  } from './actions';

function* fetchOptionsData(props) {
  const { globalActions: { fetchDictionaries } } = props;
  const optionsResources = getOptionsDataResources(colDef);


  yield put(fetchDictionaries(optionsResources));
}

function* deletePersonHandler(props, { payload: { id, callback: refreshGrid } }) {
  const { globalActions: { enqueueSnackbar } } = props;

  try {
    yield call(apiMethods.delete, `people/${id}`);

    yield put(enqueueSnackbar({
      message: 'Person successfully deleted!',
      options: { variant: 'success' }
    }))

    refreshGrid();
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  }
}

function* savePersonHandler(props, { payload: { data, callback: refreshGrid } }) {
  const { globalActions: { enqueueSnackbar } } = props;

  const { id } = data;
  const method = id ? 'update' : 'create';
  const url = `people/${id ? id : ''}`;

  try {
    yield call(apiMethods[method], url, { data })

    yield put(enqueueSnackbar({
      message: 'Person successfully saved!',
      options: { variant: 'success' },
    }))

    refreshGrid();
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  }
}

function* loadItemHandler(props, { payload: id }) {
  const { globalActions: { enqueueSnackbar } } = props;

  try {
    if (id) {
      const { data } = yield call(apiMethods.get, `people/${id}`);

      yield put(setItem(data));
    } else {
      yield put(setItem({
        active: true,
        trackPto: true,
        immediatePtoExpense: false,
      }))
    }
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  } finally {
    yield put(setPeopleLoading(false))
  }
}

export default function* peopleSagaWatcher(props) {
  yield call(fetchOptionsData, props);

  yield takeLatest(SAVE_PERSON, savePersonHandler, props);
  yield takeLatest(DELETE_PERSON, deletePersonHandler, props);
  yield takeLatest(LOAD_ITEM, loadItemHandler, props);
}
