export const SET_LIST = 'SET_PERIODS_LIST';
export const CREATE = 'CREATE_PERIOD';
export const REMOVE = 'REMOVE_PERIOD';
export const SAVE = 'SAVE_PERIOD';

export const CHECK_CALCULATION_STATUS = 'CHECK_CALCULATION_STATUS';
export const CALCULATE_EXPENSES = 'CALCULATE_EXPENSES';
export const CLOSE_PERIOD = 'CLOSE_PERIOD';

export const SET_CALCULATION_STATUS = 'SET_CALCULATION_STATUS';
