import apiMethods from 'src/api/apiMethods';
import { setAuditlog, loadAuditLog } from 'src/modules/auditLog/actions/index';
import { call, put } from 'redux-saga/effects';

export default function* auditLogSaga(props, { payload: { entityName, entityId } }) {
  const {
    globalActions: { enqueueSnackbar }
  } = props;

  try {
    const data = yield call(apiMethods.get, `auditLog`, { filter: { entityName, entityId } });
    yield put(setAuditlog(data));
    yield put(loadAuditLog(true));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.toString() || 'Download error!',
        options: { variant: 'error', style: { whiteSpace: 'pre-line' } }
      })
    );
  } finally {
    yield put(loadAuditLog(true));
  }
}
