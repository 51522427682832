export const LOAD_ITEM = 'LOAD_ITEM_INVOICES';
export const SET_ITEM = 'SET_ITEM_INVOICES';
export const SAVE = 'SAVE_INVOICES';
export const REMOVE = 'REMOVE_INVOICES';
export const CHANGE_HANDLER = 'CHANGE_HANDLER';
export const PREVIEW_INVOICE_PDF = 'PREVIEW_INVOICE_PDF';
export const SEND_TO_CLIENT = 'SEND_TO_CLIENT';
export const SET_CURRENT_PROJECTS = 'SET_CURRENT_PROJECTS';
export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const SAT_TIME_SHEETS_LIST = 'SAT_TIME_SHEETS_LIST';
export const SET_CURRENT_INVOICE_TEMPLATES = 'SET_CURRENT_INVOICE_TEMPLATES';
export const FETCH_CURRENT_INVOICE_TEMPLATES = 'FETCH_CURRENT_INVOICE_TEMPLATES';
export const SET_LOAD = 'SET_LOAD';
