import { translate } from 'src/utils';

export default [
  {
    field: 'id',
    colId: 'id',
    headerName: translate('views.invoicesNew.fields.id'),
    cellRenderer: 'loadingRenderer',
    editable: false,
    minWidth: 80,
    maxWidth: 80,
    filter: 'agNumberColumnFilter',
    floatingFilterComponentParams: { suppressMenu: false, suppressFilterButton: false },
  },
  {
    field: 'client',
    colId: 'clientId',
    type: 'dropdown',
    optionsAccessor: 'clients'
  },
  {
    field: 'status',
    colId: 'status',
    type: 'text'
  },
  {
    field: 'totalAmount',
    colId: 'totalAmount',
    type: 'number',
    filter: 'agNumberColumnFilter',
    floatingFilterComponentParams: { suppressMenu: false, suppressFilterButton: false },
  },
  {
    field: 'dueDate',
    colId: 'dueDate',
    type: 'text'
  },
  {
    field: 'periodStart',
    colId: 'periodStart',
    type: 'text'
  },
  {
    field: 'periodEnd',
    colId: 'periodEnd',
    type: 'text'
  },
  {
    field: 'paymentTerm',
    colId: 'paymentTerm',
    type: 'text'
  }
];
