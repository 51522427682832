import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TextField
} from '@material-ui/core';

import Dialog from 'src/components/Dialog';

const InvoiceProject = ({ formData, optionsAccessor, name, value, onChange, ...props }) => {
  const dictionary = useSelector(state => state.dictionaries[optionsAccessor] || []);
  const [openProjectAdd, setOpenProjectAdd] = useState(false);
  const [newProject, setNewProject] = useState({ projectId: '', amount: 0 });

  const handleOpenAdd = () => {
    setOpenProjectAdd(true);
  }

  const handleCloseAdd = () => {
    setOpenProjectAdd(false);
    setNewProject({ projectId: '', amount: '' });
  }

  const handleAdd = () => {
    setOpenProjectAdd(false);
    onChange({ target: { name, value: [...value, newProject] } });
    setNewProject({ projectId: '', amount: 0 });
  }

  const getProjectName = id => {
    const item = dictionary.find(({ key }) => key === id);

    return item ? item.value : ''
  }

  const changeAmount = projectId => event => {
    const data = [...value];
    const project = data.find(item => item.projectId === projectId);
    project.amount = Number(event.target.value);

    onChange({ target: { name, value: data } });
  }

  const handleClickRemoveButton = projectId => () => {
    const data = value.filter(item => item.projectId !== projectId);

    onChange({ target: { name, value: data } });

  }

  return <>
    <Dialog
      open={openProjectAdd}
      title="Add new project"
      onOk={handleAdd}
      onCancel={handleCloseAdd}
      maxWidth="xs"
    >
      Add new project form
      {JSON.stringify(formData.clientId)}
      <Select
        fullWidth
        displayEmpty
        value={newProject.projectId}
        onChange={({ target }) => setNewProject({ ...newProject, projectId: target.value })}
      >
        <MenuItem value="">Select project</MenuItem>
        {dictionary.filter(({ clientId }) => clientId === formData.clientId).map(item => (
          <MenuItem key={item.key} value={item.key}>
            {item.value}
          </MenuItem>
        ))}
      </Select>
      <TextField
        fullWidth
        margin="dense"
        placeholder="amount"
        type="number"
        value={newProject.amount}
        onChange={({ target }) => setNewProject({ ...newProject, amount: Number(target.value) })}
        variant="standard" />
    </Dialog>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell component="th">Project</TableCell>
          <TableCell component="th">Amount</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {value.map(project => (
          <TableRow key={project.projectId}>
            <TableCell>
              {getProjectName(project.projectId)}
            </TableCell>
            <TableCell>
              <TextField
                fullWidth
                type="number"
                value={project.amount}
                onChange={changeAmount(project.projectId)}
                variant="standard" />
            </TableCell>
            <TableCell align="right">
              <Button variant="contained" color="secondary" onClick={handleClickRemoveButton(project.projectId)}>
                Remove
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <Box mb={1} />
    <Button variant="contained" color="secondary" onClick={handleOpenAdd}>
      Add new project
    </Button>
  </>;
};

export default InvoiceProject;
