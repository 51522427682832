import React, {Suspense, Fragment, lazy} from 'react';
import {Switch, Redirect, Route} from 'react-router-dom';

import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';

import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import PermissionGuard from "./components/PremissionGuard";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen/>}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  <PermissionGuard permissionName={route.permissionName}>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </PermissionGuard>
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/Login')),
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/Login')),
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/dashboard',
        component: lazy(() => import('src/views/Dashboard')),
      },
      {
        exact: true,
        path: '/people',
        component: lazy(() => import('src/views/People')),
      },
      {
        exact: true,
        path: '/projects',
        component: lazy(() => import('src/views/Projects')),
      },
      {
        exact: true,
        path: '/clients',
        component: lazy(() => import('src/views/Clients')),
      },
      {
        exact: true,
        path: '/departments',
        component: lazy(() => import('src/views/Departments')),
      },
      {
        exact: true,
        path: '/moneyTypes',
        component: lazy(() => import('src/views/MoneyTypes')),
      },
      {
        exact: true,
        path: '/vendors',
        component: lazy(() => import('src/views/Vendors')),
      },
      {
        exact: true,
        path: '/expenseCategories',
        component: lazy(() => import('src/views/ExpenseCategories')),
      },
      {
        exact: true,
        path: '/periods',
        component: lazy(() => import('src/views/Periods')),
      },
      {
        exact: true,
        path: '/depreciationRules',
        component: lazy(() => import('src/views/DepreciationRules')),
      },
      {
        exact: true,
        path: '/locations',
        component: lazy(() => import('src/views/Locations')),
      },
      {
        exact: true,
        path: '/assetItems',
        component: lazy(() => import('src/views/Assets')),
      },
      {
        exact: true,
        path: '/importMappings',
        component: lazy(() => import('src/views/ImportMappings')),
      },
      {
        exact: true,
        path: '/periodSalaries',
        component: lazy(() => import('src/views/PeriodSalaries')),
      },
      {
        exact: true,
        path: '/timeSheets',
        component: lazy(() => import('src/views/TimeSheets')),
      },
      {
        exact: true,
        path: '/import',
        component: lazy(() => import('src/views/Import')),
      },
      {
        exact: true,
        path: '/transactions',
        component: lazy(() => import('src/views/Transactions')),
      },
      {
        exact: true,
        path: '/invoices',
        component: lazy(() => import('src/views/Invoices')),
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: <div>main</div>
      },
      {
        component: () => <Redirect to="/404"/>
      }
    ]
  }
];

export default routes;
