import {
  LOAD_ITEM,
  SET_ITEM,
  CHANGE_HANDLER,
  SET_CURRENT_PROJECTS,
  SET_LOAD,
  SAT_TIME_SHEETS_LIST, SET_CURRENT_INVOICE_TEMPLATES
} from './actions/actionTypes';
import dateFormatter from "src/utils/dateFormatter";
const currentDate = new Date()
export const initialItemState = {
  status: "Open",
  date: dateFormatter(currentDate),
  periodStart: dateFormatter(currentDate),
  periodEnd: dateFormatter(currentDate),
  dueDate: dateFormatter(currentDate),
  lineItems: [],
  totalAmount: 0,
  templateId: null,
  appearances: {
    aggregateSameItems: true,
    showSectionTotals: true,
    itemDisplay: 'Role, ResourceName',
    divideIntoSections: 'ByProject',
    columns: {
      item: {title: '', visible: true},
      quantity: {title: '', visible: true},
      rate: {title: '', visible: true},
      unit: {title: '', visible: true},
      discount: {title: '', visible: true},
      amount: {title: '', visible: true},
    },
  }
}

const initialState = {
  isFetched: {},
  item: {...initialItemState},
  hasChanges: false,
  currentProjects: [],
  timeSheetsList: [],
  currentInvoiceTemplates: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_ITEM:
      return {
        ...state,
        isFetched: {...state.isFetched, item: true}
      };
    case SET_ITEM:
      return {
        ...state,
        isFetched: {...state.isFetched, item: false},
        item: payload
      };
    case CHANGE_HANDLER:
      return {
        ...state,
        hasChanges: payload,
      };
    case SET_CURRENT_PROJECTS:
      return {
        ...state,
        currentProjects: payload,
      };
    case SAT_TIME_SHEETS_LIST:
      return {
        ...state,
        timeSheetsList: payload,
      };
    case SET_LOAD:
      return {
        ...state,
        isFetched: {...state.isFetched, [payload.field]: payload.value},
      };
    case SET_CURRENT_INVOICE_TEMPLATES:
      return {
        ...state,
        currentInvoiceTemplates: payload,
      };
    default:
      return state;
  }
};
