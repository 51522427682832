export const SET_LIST = 'SET_CLIENTS_LIST';
export const SET_LOAD_CLIENT = 'SET_LOAD_CLIENT';
export const CREATE = 'CREATE_CLIENT';
export const REMOVE = 'REMOVE_CLIENT';
export const SAVE = 'SAVE_CLIENT';

// invoice templates

export const SET_INVOICE_TEMPLATES_LIST = 'SET_INVOICE_TEMPLATES_LIST';
export const SET_LOAD_INVOICE_TEMPLATES_LIST = 'SET_LOAD_INVOICE_TEMPLATES_LIST';
export const CREATE_INVOICE_TEMPLATE = 'CREATE_INVOICE_TEMPLATE';
export const REMOVE_INVOICE_TEMPLATE = 'REMOVE_INVOICE_TEMPLATE';
export const SAVE_INVOICE_TEMPLATE = 'SAVE_INVOICE_TEMPLATE';
export const SAT_PROJECTS_LIST = 'SAT_PROJECTS_LIST';
export const SAT_CURRENT_PROJECT_LIST = 'SAT_CURRENT_PROJECT_LIST';
export const FETCH_CURRENT_INVOICE_TEMPLATES = 'FETCH_CURRENT_INVOICE_TEMPLATES';
export const CHANGE_HANDLER = 'CHANGE_HANDLER';
