import { FETCH_DICTIONARIES } from 'src/modules/dictionaries/actions/actionTypes';
import {FETCH_AUDITLOG} from 'src/modules/auditLog/actions/actionTypes'
const NAVIGATE_TO = 'NAVIGATE_TO';
const SET_ACTIVE_PERIOD = 'SET_ACTIVE_PERIOD';

export {
  NAVIGATE_TO,
  FETCH_DICTIONARIES,
  SET_ACTIVE_PERIOD,
  FETCH_AUDITLOG
};
