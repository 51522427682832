import {
  SET_LIST,
  CREATE,
  REMOVE,
  SAVE,
  CALCULATE_EXPENSES,
  CLOSE_PERIOD,
  SET_CALCULATION_STATUS
} from './actionTypes';

export const setList = data => ({
  type: SET_LIST,
  payload: data
});

export const create = () => ({
  type: CREATE
});

export const remove = id => ({
  type: REMOVE,
  payload: id
});

export const save = data => ({
  type: SAVE,
  payload: data
});

export const closePeriod = () => ({
  type: CLOSE_PERIOD
});

export const calculateExpenses = () => ({
  type: CALCULATE_EXPENSES
});

export const setCalculationStatus = status => ({
  type: SET_CALCULATION_STATUS,
  payload: status
});
