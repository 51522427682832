import React from 'react';
import { FormControl, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  field: {
    marginTop: theme.spacing(2),
  },
}));

const Text = ({ formData, ...props }) => {
  const theme = useStyles();

  return (
    <FormControl fullWidth className={theme.formControl}>
      <TextField {...props} className={theme.field} />
    </FormControl>
  );
};

export default Text;
