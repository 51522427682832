import {
  LOAD_ITEM,
  SET_ITEM,
  DELETE_ASSET,
  SAVE_ASSET,
  ADD_DEPRECIATION,
  REMOVE_DEPRECIATION,
  REMOVE_ADD_DEPRECIATION
} from './actionTypes';

export const loadItem = id => ({
  type: LOAD_ITEM,
  payload: id
});

export const setItem = data => ({
  type: SET_ITEM,
  payload: data
});

export const saveAsset = (data, callback) => ({
  type: SAVE_ASSET,
  payload: { data, callback }
});

export const deleteAsset = (id, callback) => ({
  type: DELETE_ASSET,
  payload: { id, callback }
});
export const addDepreciation = () => ({ type: ADD_DEPRECIATION });
export const removeDepreciation = () => ({ type: REMOVE_DEPRECIATION });
export const removeAddDepreciation = () => ({ type: REMOVE_ADD_DEPRECIATION });
