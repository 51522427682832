export const SET_PROJECTS_LIST = 'SET_PROJECTS_LIST';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const REMOVE_PROJECT = 'REMOVE_PROJECT';
export const SAVE_PROJECT = 'SAVE_PROJECT';
export const SET_LOAD_PROJECTS_LIST = 'SET_LOAD_PROJECTS_LIST';

export const CHANGE_HANDLER = 'CHANGE_HANDLER';

// billing rules

export const SET_BILLING_RULES_LIST = 'SET_BILLING_RULES_LIST';
export const SET_LOAD_BILLING_RULES_LIST = 'SET_LOAD_BILLING_RULES_LIST';
export const CREATE_BILLING_RULE = 'CREATE_BILLING_RULE';
export const REMOVE_BILLING_RULE = 'REMOVE_BILLING_RULE';
export const SAVE_BILLING_RULE = 'SAVE_BILLING_RULE';
export const FETCH_BILLING_RULES = 'FETCH_BILLING_RULES';

// task

export const SET_TASKS_LIST = 'SET_TASKS_LIST';
export const SET_LOAD_TASKS_LIST = 'SET_LOAD_TASKS_LIST';
export const CREATE_TASK = 'CREATE_TASK';
export const REMOVE_TASK = 'REMOVE_TASK';
export const SAVE_TASK = 'SAVE_TASK';
export const FETCH_TASKS_LIST = 'FETCH_TASKS_LIST';
