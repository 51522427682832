import {
  SET_LIST,
  CREATE,
  REMOVE,
  SAVE,

  // invoice templates

  SET_INVOICE_TEMPLATES_LIST,
  CREATE_INVOICE_TEMPLATE,
  REMOVE_INVOICE_TEMPLATE,
  SAVE_INVOICE_TEMPLATE,
  SAT_PROJECTS_LIST,
  SAT_CURRENT_PROJECT_LIST,
  CHANGE_HANDLER,
  FETCH_CURRENT_INVOICE_TEMPLATES,
  SET_LOAD_INVOICE_TEMPLATES_LIST, SET_LOAD_CLIENT,
} from './actionTypes';

export const setList = data => ({
  type: SET_LIST,
  payload: data
});

export const setLoadClientList = isLoad => ({
  type: SET_LOAD_CLIENT,
  payload: isLoad
});

export const create = () => ({
  type: CREATE
});

export const remove = id => ({
  type: REMOVE,
  payload: id
});

export const save = data => ({
  type: SAVE,
  payload: data
});

// invoice templates

export const setInvoiceTemplatesList = data => ({
  type: SET_INVOICE_TEMPLATES_LIST,
  payload: data
});

export const setLoadInvoiceTemplatesList = isLoad => ({
  type: SET_LOAD_INVOICE_TEMPLATES_LIST,
  payload: isLoad
});

export const createInvoiceTemplate = () => ({
  type: CREATE_INVOICE_TEMPLATE
});

export const removeInvoiceTemplate = template => ({
  type: REMOVE_INVOICE_TEMPLATE,
  payload: template
});

export const saveInvoiceTemplate = data => ({
  type: SAVE_INVOICE_TEMPLATE,
  payload: data
});

export const setCurrentProjectList = id => ({
  type: SAT_CURRENT_PROJECT_LIST,
  payload: id
})

export const fetchCurrentInvoiceTemplatesList = id => ({
  type: FETCH_CURRENT_INVOICE_TEMPLATES,
  payload: id
})

export const setProjectList = data => ({
  type: SAT_PROJECTS_LIST,
  payload: data
});

export const changeHandler = data => ({
  type: CHANGE_HANDLER,
  payload: {hasChanges: data}
});
