import { translate } from 'src/utils';

export default [
  {
    field: 'id',
    colId: 'id',
    headerName: translate('views.assets.fields.id'),
    cellRenderer: 'loadingRenderer',
    minWidth: 80,
    maxWidth: 80,
    filter: 'agNumberColumnFilter',
    floatingFilterComponentParams: { suppressMenu: false, suppressFilterButton: false },
  },
  {
    field: 'name',
    filter: 'agTextColumnFilter',
  },
  {
    field: "ExpenseCategory",
    colId: 'expenseCategoriesId',
    type: 'dropdown',
    optionsAccessor: 'expenseCategories',
    minWidth: 210
  },
  {
    field: 'assignee',
    editable: false,
    sortable: false,
    cellRenderer: 'assigneeCellRenderer',
    filter: 'assigneeFilter',
    floatingFilter: true,
    floatingFilterComponent: 'assigneeFloatingFilter',
    minWidth: 300
  },
  {
    field: 'cost',
    type: 'number',
    filter: 'agNumberColumnFilter',
    floatingFilterComponentParams: { suppressMenu: false, suppressFilterButton: false },
  },
  {
    field: 'ruleId',
    colId: 'ruleId',
    type: 'dropdown',
    optionsAccessor: 'depreciationRules',
    label: 'Depreciation Rules'
  },
  {
    field: 'amount',
    headerName: 'salvageValue',
    type: 'number',
    filter: 'agNumberColumnFilter',
    floatingFilterComponentParams: { suppressMenu: false, suppressFilterButton: false },
  },
  {
    field: 'active',
    type: 'boolean'
  }
];
