import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import dictionariesReducer from 'src/modules/dictionaries/reducer';
import auditlogReducer from 'src/modules/auditLog/reducer'
import loadingManagerReducer from 'src/modules/loadingManager/reducer';
import notifications from 'src/modules/notifier/reducer';

import rootReducer from './rootReducer';

import projects from 'src/views/Projects/reducer';
import locations from 'src/views/Locations/reducer';
import clients from 'src/views/Clients/reducer';
import departments from 'src/views/Departments/reducer';
import moneyTypes from 'src/views/MoneyTypes/reducer';
import vendors from 'src/views/Vendors/reducer';
import expenseCategories from 'src/views/ExpenseCategories/reducer';
import periods from 'src/views/Periods/reducer';
import depreciationRules from 'src/views/DepreciationRules/reducer';
import transactions from 'src/views/Transactions/reducer';
import invoices from 'src/views/Invoices/reducer';
import assets from 'src/views/Assets/reducer';
import importMappings from 'src/views/ImportMappings/reducer';
import people from 'src/views/People/reducer';
import periodSalaries from 'src/views/PeriodSalaries/reducer';
import timeSheets from 'src/views/TimeSheets/reducer';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    root: rootReducer,
    dictionaries: dictionariesReducer,
    auditLog:auditlogReducer,
    loadingManager: loadingManagerReducer,
    projects,
    locations,
    clients,
    departments,
    moneyTypes,
    vendors,
    expenseCategories,
    periods,
    depreciationRules,
    notifications,
    transactions,
    invoices,
    assets,
    importMappings,
    people,
    periodSalaries,
    timeSheets
  });

export default createRootReducer;
