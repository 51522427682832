import { LOAD_ITEM, SET_ITEM, SAVE, REMOVE } from './actionTypes';

export const loadItem = id => ({
  type: LOAD_ITEM,
  payload: id
});

export const setItem = data => ({
  type: SET_ITEM,
  payload: data
});

export const save = (data, callback) => ({
 type: SAVE,
 payload: { data, callback }
});

export const remove = (id, callback) => ({
  type: REMOVE,
  payload: { id, callback }
});
