import {
  SET_LIST,
  CREATE,
  REMOVE,
  SAVE
} from './actionTypes';

export const setList = data => ({
  type: SET_LIST,
  payload: data
});

export const create = () => ({
  type: CREATE
});

export const remove = id => ({
  type: REMOVE,
  payload: id
});

export const save = data => ({
  type: SAVE,
  payload: data
});
