import { translate } from 'src/utils';

export default [
  {
    field: 'id',
    colId: 'id',
    headerName: translate('views.people.fields.id'),
    cellRenderer: 'loadingRenderer',
    editable: false,
    minWidth: 80,
    maxWidth: 80,
    filter: 'agNumberColumnFilter',
    floatingFilterComponentParams: { suppressMenu: false, suppressFilterButton: false },
  },
  {
    field: 'date',
    colId: 'date',
    type: 'date',
    filter: 'agDateColumnFilter',
    filterParams: {
      // provide comparator function
      comparator: function(filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;

        if (dateAsString == null) {
          return 0;
        }

        // In the example application, dates are stored as dd/mm/yyyy
        // We create a Date object for comparison against the filter date
        var dateParts = dateAsString.split('/');
        var day = Number(dateParts[2]);
        var month = Number(dateParts[1]) - 1;
        var year = Number(dateParts[0]);
        var cellDate = new Date(year, month, day);

        // Now that both parameters are Date objects, we can compare
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        } else {
          return 0;
        }
      },
      buttons: ['reset'],
      suppressFilterButton: false,
      suppressMenu: false
    },
    floatingFilterComponentParams: { suppressMenu: false, suppressFilterButton: false },
    minWidth: 180
  },
  {
    field: 'amount',
    colId: 'amount',
    type: 'number',
    filter: 'agNumberColumnFilter',
    filterParams: {
      allowedCharPattern: '\\d\\-\\,', // note: ensure you escape as if you were creating a RegExp from a string
      numberParser: function(text) {
        return text == null ? null : parseFloat(text.replace(',', '.'));
      }
    },
    floatingFilterComponentParams: { suppressMenu: false, suppressFilterButton: false },
  },
  {
    field: 'fromType',
    colId: 'fromType',
    type: 'dropdown',
    optionsAccessor: 'transactionTypes',
    editable: false
  },
  {
    field: 'fromItem',
    colId: 'fromItem',
    type: 'text',
    editable: false,
    minWidth: 200
  },
  {
    field: 'toType',
    colId: 'toType',
    type: 'dropdown',
    optionsAccessor: 'transactionTypes',
    editable: false
  },
  {
    field: 'toItem',
    colId: 'toItem',
    type: 'text',
    editable: false,
    minWidth: 200
  },
  {
    field: 'description',
    colId: 'description',
    type: 'text',
    minWidth: 200
  },
  {
    field: 'source',
    colId: 'source',
    type: 'dropdown',
    optionsAccessor: 'transactionSources',
    editable: false
  }
];
