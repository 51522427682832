import {call, put, takeLatest, select} from 'redux-saga/effects';
import apiMethods from 'src/api/apiMethods';
import {
  setBillingRulesList,
  setLoadBillingRulesList,
  setLoadProjectsList,
  setLoadTasksList,
  setProjectsList,
  setTasksList
} from './actions';
import {
  CREATE_PROJECT,
  REMOVE_PROJECT,
  SAVE_PROJECT,

  // billing rules

  CREATE_BILLING_RULE,
  REMOVE_BILLING_RULE,
  SAVE_BILLING_RULE,
  FETCH_BILLING_RULES,
  // tasks

  CREATE_TASK,
  REMOVE_TASK,
  SAVE_TASK,
  FETCH_TASKS_LIST,

} from './actions/actionTypes';

function* saveProjectHandler(props, {payload: {id, ...data}}) {
  if (Object.keys(data).length) {
    const {globalActions: {enqueueSnackbar, setLoading}} = props;
    const url = id ? `projects/${id}` : 'projects';
    const method = id ? 'update' : 'create';

    yield put(setLoading(!id));
    yield put(setLoadProjectsList(false))

    try {
      yield call(apiMethods[method], url, {data});
      const result = yield call(fetchProjectsList, props, !id);
      yield put(enqueueSnackbar({
        message: 'Project successfully saved!',
        options: {variant: 'success'}
      }))
      if (result && result.ok) {
        return Promise.resolve(result)
      }
    } catch (error) {

      yield put(enqueueSnackbar({
        message: error.toString() || 'Project non saved!',
        options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
      }))
    } finally {
      yield put(setLoading(false));
      yield put(setLoadProjectsList(true))
    }
  } else {
    const {total, list} = yield select(state => state.projects);
    yield put(setProjectsList({data: list.filter(item => item.id), total: total - 1}));
  }
}

function* removeProjectHandler(props, {payload: id}) {
  const {globalActions: {enqueueSnackbar, setLoading}} = props;
  yield put(setLoading(true));
  yield put(setLoadProjectsList(false))

  try {
    yield call(apiMethods.delete, `projects/${id}`);
    yield call(fetchProjectsList, props);
    yield put(enqueueSnackbar({
      message: 'Project successfully deleted!',
      options: {variant: 'success'}
    }))
  } catch (error) {

    yield put(enqueueSnackbar({
      message: error.toString() ||  'Rule not deleted!',
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  } finally {
    yield put(setLoading(false));
    yield put(setLoadProjectsList(true))
  }
}

function* createProjectHandler() {
  const {total, list} = yield select(state => state.projects);
  const newItem = list.find(item => !item.id);

  if (!newItem) {
    yield put(setProjectsList({data: [...list, {active: false}], total: total + 1}));
  }
}

function* fetchOptionsData(props) {
  const {globalActions: {fetchDictionaries}} = props;
  const optionsResources = ['people', 'clients', 'departments', 'billingTypes', 'locations'];

  yield put(fetchDictionaries(optionsResources));
}

function* fetchProjectsList(props, load = true) {
  const {globalActions: {setLoading, enqueueSnackbar}} = props;
  yield put(setLoading(load));

  try {
    const response = yield call(apiMethods.get, 'projects');

    yield put(setProjectsList(response));
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString() ,
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  } finally {
    yield put(setLoading(false));
  }
}

// billing rules

const RESOURCE = 'billingRules';

function* saveBillingRuleHandler(props, {payload: {id, ...data}}) {
  if (Object.keys(data).length) {
    const {globalActions: {enqueueSnackbar}} = props;
    const url = id ? `${RESOURCE}/${id}` : RESOURCE;
    const method = id ? 'update' : 'create';

    yield put(setLoadBillingRulesList(false))

    try {
      yield call(apiMethods[method], url, {data});
      yield call(fetchBillingRulesList, props, {payload: data.projectId});
      yield call(fetchProjectsList, props, false)
      yield put(enqueueSnackbar({
        message: 'Rule successfully saved!',
        options: {variant: 'success'}
      }))
    } catch (error) {
      yield put(enqueueSnackbar({
        message: error.toString() ||  'Rule not saved!',
        options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
      }))
    } finally {
      yield put(setLoadBillingRulesList(true))
    }
  } else {
    const {total, rules} = yield select(state => state.billingRules);
    yield put(setBillingRulesList({data: rules.filter(item => item.id), total: total - 1}));
  }
}

function* removeBillingRuleHandler(props, {payload: {id, projectId}}) {
  const {globalActions: {enqueueSnackbar}} = props;
  yield put(setLoadBillingRulesList(false))

  try {
    yield call(apiMethods.delete, `${RESOURCE}/${id}`);
    yield call(fetchBillingRulesList, props, {payload: projectId});
    yield call(fetchProjectsList, props, false)
    yield put(enqueueSnackbar({
      message: 'Rule successfully deleted!',
      options: {variant: 'success'}
    }))
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString() ||  'Rule not deleted!',
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  } finally {
    yield put(setLoadBillingRulesList(true))
  }
}

function* createBillingRuleHandler() {
  const {total, rules} = yield select(state => state.billingRules);
  const newItem = rules.find(item => !item.id);

  if (!newItem) {
    yield put(setBillingRulesList({data: [...rules, {active: false}], total: total + 1}));
  }
}

function* fetchBillingRulesList(props, {payload: id}) {
  try {
    const {data} = yield call(apiMethods.get, RESOURCE, {filter: {projectId: id}});
    console.log(data)
    yield put(setBillingRulesList(data));
  } catch (error) {
    // TODO: show notification
    console.log('fetch rules error:' + error)
  } finally {
  }
}

// tasks

function* saveTaskHandler(props, {payload: {id, ...data}}) {
  if (Object.keys(data).length) {
    const {globalActions: {enqueueSnackbar}} = props;
    const url = id ? `tasks/${id}` : 'tasks';
    const method = id ? 'update' : 'create';

    yield put(setLoadTasksList(false))

    try {
      yield call(apiMethods[method], url, {data});
      yield call(fetchTasksList, props, {payload: data.projectId});
      yield call(fetchProjectsList, props, false)
      yield put(enqueueSnackbar({
        message: 'Task successfully saved!',
        options: {variant: 'success'}
      }))
    } catch (error) {
      console.log(error);

      yield put(enqueueSnackbar({
        message: error.toString() ||  'Task not saved!',
        options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
      }))
    } finally {
      yield put(setLoadTasksList(true))
    }
  } else {
    const {total, rules} = yield select(state => state.billingRules);
    yield put(setTasksList({data: rules.filter(item => item.id), total: total - 1}));
  }
}

function* removeTaskHandler(props, {payload: {id, projectId}}) {
  const {globalActions: {enqueueSnackbar}} = props;
  yield put(setLoadTasksList(false))

  try {
    yield call(apiMethods.delete, `tasks/${id}`);
    yield call(fetchTasksList, props, {payload: projectId});
    yield call(fetchProjectsList, props, false)
    yield put(enqueueSnackbar({
      message: 'Task successfully deleted!',
      options: {variant: 'success'}
    }))
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString() ||  'Task not deleted!',
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  } finally {
    yield put(setLoadTasksList(true))
  }
}

function* createTaskHandler() {
  const {total, rules} = yield select(state => state.billingRules);
  const newItem = rules.find(item => !item.id);

  if (!newItem) {
    yield put(setTasksList({data: [...rules, {active: false}], total: total + 1}));
  }
}

function* fetchTasksList(props, {payload: id}) {
  try {
    const response = yield call(apiMethods.get, 'tasks', {filter: {projectId: id}});

    yield put(setTasksList(response));
  } catch (error) {
    // TODO: show notification
  } finally {
  }
}

export default function* projectsSagaWatcher(props) {
  yield call(fetchOptionsData, props);
  yield call(fetchProjectsList, props);

  yield takeLatest(CREATE_PROJECT, createProjectHandler);
  yield takeLatest(REMOVE_PROJECT, removeProjectHandler, props);
  yield takeLatest(SAVE_PROJECT, saveProjectHandler, props);

  // billing rules

  yield takeLatest(CREATE_BILLING_RULE, createBillingRuleHandler);
  yield takeLatest(REMOVE_BILLING_RULE, removeBillingRuleHandler, props);
  yield takeLatest(SAVE_BILLING_RULE, saveBillingRuleHandler, props);
  yield takeLatest(FETCH_BILLING_RULES, fetchBillingRulesList, props);

  // tasks

  yield takeLatest(CREATE_TASK, createTaskHandler);
  yield takeLatest(REMOVE_TASK, removeTaskHandler, props);
  yield takeLatest(SAVE_TASK, saveTaskHandler, props);
  yield takeLatest(FETCH_TASKS_LIST, fetchTasksList, props);
}
