export default theme => ({
  dialogRoot: {
    backgroundColor: theme.backgroundColor,
    maxWidth: '50%',

    '&dialogContent': {
      overflowY: 'auto',
    }
  },
});
