import {
  PieChart as PieChartIcon,
  Briefcase as BriefcaseIcon,
} from "react-feather";
import LocationCityIcon from '@material-ui/icons/LocationCity';
import LaptopIcon from '@material-ui/icons/Laptop';
import ClientsIcon from '@material-ui/icons/People';
import DepartmentsIcon from '@material-ui/icons/BusinessCenter';
import VendorsIcon from '@material-ui/icons/StoreMallDirectory';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeriodsIcon from '@material-ui/icons/DateRange';
import ExpenseCategoriesIcon from '@material-ui/icons/Category';
import DepreciationRulesIcon from '@material-ui/icons/TrendingDown';
import ImportMappingsIcon from '@material-ui/icons/AllInbox';
import PeriodSalariesIcon from '@material-ui/icons/CreditCard';
import TimeSheetsIcon from '@material-ui/icons/ListAlt';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import InvoicesIcon from '@material-ui/icons/Receipt';
import { translate } from 'src/utils';

export const sections = [
  {
    id: 'mainSection',
    items: [
      {
        title: translate('menu.dashboard'),
        icon: PieChartIcon,
        href: '/dashboard'
      },
      {
        title: translate('menu.assetItems'),
        icon: LaptopIcon,
        href: '/assetItems'
      },
      {
        title: translate('menu.clients'),
        icon: ClientsIcon,
        href: '/clients'
      },
      {
        title: translate('menu.people'),
        icon: EmojiPeopleIcon,
        href: '/people'
      },
      {
        title: translate('menu.locations'),
        icon: LocationCityIcon,
        href: '/locations'
      },
      {
        title: translate('menu.departments'),
        icon: DepartmentsIcon,
        href: '/departments'
      },
      {
        title: translate('menu.depreciationRules'),
        icon: DepreciationRulesIcon,
        href: '/depreciationRules'
      },
      {
        title: translate('menu.expenseCategories'),
        icon: ExpenseCategoriesIcon,
        href: '/expenseCategories'
      },
      {
        title: translate('menu.projects'),
        icon: BriefcaseIcon,
        href: '/projects'
      },
      {
        title: translate('menu.vendors'),
        icon: VendorsIcon,
        href: '/vendors'
      },
      {
        title: translate('menu.moneyTypes'),
        icon: AttachMoneyIcon,
        href: '/moneyTypes'
      },
      {
        title: translate('menu.periods'),
        icon: PeriodsIcon,
        href: '/periods'
      },
      {
        title: translate('menu.timeSheets'),
        icon: TimeSheetsIcon,
        href: '/timeSheets'
      },
      {
        title: translate('menu.import'),
        icon: ImportExportIcon,
        href: '/import'
      },
      {
        title: translate('menu.importMappings'),
        icon: ImportMappingsIcon,
        href: '/importMappings'
      },
      {
        title: translate('menu.periodSalaries'),
        icon: PeriodSalariesIcon,
        href: '/periodSalaries'
      },
      {
        title: translate('menu.transactions'),
        icon: LocalAtmIcon,
        href: '/transactions'
      },
      {
        title: translate('menu.invoices'),
        icon: InvoicesIcon,
        href: '/invoices'
      }
    ]
  }
];
