import rolesPermissions from 'bpaConfig/roles';
import React from "react";
// import rolesPermissions from './roles';

// Permissions
const SHOW = 'show';
const READ = 'read'; // eslint-disable-line no-unused-vars
const WRITE = 'write';
const ALL = 'all';

// Permissions list with links

const permissions = [
  {
    path: '/404',
    permissionName: 'permissed',
  },
  {
    path: '/login',
    permissionName: 'permissed',
  },
  {
    path: '/login-unprotected',
    permissionName: 'permissed',
  },
  {
    path: '/',
    permissionName: 'permissed',
  },
  {
    path: '/dashboard',
    permissionName: 'permissed',
  },
  {
    path: '/people',
    permissionName: 'People',
  },
  {
    path: '/projects',
    permissionName: 'Projects',
  },
  {
    path: '/clients',
    permissionName: 'Clients',
  },
  {
    path: '/departments',
    permissionName: 'Departments',
  },
  {
    path: '/moneyTypes',
    permissionName: 'MoneyTypes',
  },
  {
    path: '/vendors',
    permissionName: 'Vendors',
  },
  {
    path: '/expenseCategories',
    permissionName: 'ExpenseCategories',
  },
  {
    path: '/periods',
    permissionName: 'Periods',
  },
  {
    path: '/depreciationRules',
    permissionName: 'DepreciationRules',
  },
  {
    path: '/locations',
    permissionName: 'Locations',
  },
  {
    path: '/assetItems',
    permissionName: 'Assets',
  },
  {
    path: '/importMappings',
    permissionName: 'ImportMappings',
  },
  {
    path: '/periodSalaries',
    permissionName: 'PeriodSalaries',
  },
  {
    path: '/timeSheets',
    permissionName: 'TimeSheets',
  },
  {
    path: '/import',
    permissionName: 'import',
  },
  {
    path: '/transactions',
    permissionName: 'Transactions',
  },
  {
    path: '/invoices',
    permissionName: 'InvoicesNew',
  }
]
;

export const getRole = groups => {
  const roles = Object.keys(rolesPermissions);

  const role = groups.filter(item => roles.includes(item));

  return role.length > 1 ? 'error' : role[0];
};

export const getPermissions = role => rolesPermissions[role];

export const visible = (path = '', permissionsList = []) => {
  if(path){
    let {permissionName} = permissions.find(permission => permission.path === path)
    permissionName= permissionName.toLowerCase();
    return permissionName === 'permissed' ? true
      : permissionsList[permissionName] && !!permissionsList[permissionName].find(item => item === SHOW || item === ALL)
  }else {
    return false
  }

};

export const editable = (permissions = []) => permissions && permissions.find(item => item === WRITE || item === ALL);

