import { call, put, takeLatest } from 'redux-saga/effects';
import { getOptionsDataResources } from 'src/utils';
import apiMethods from 'src/api/apiMethods';
import colDef from './colDef';
import { SAVE_TRANSACTION, DELETE_TRANSACTION, LOAD_ITEM } from './actions/actionTypes';
import { setInitItemData, setItem, setTransactionItems } from './actions';

const RESOURCE = 'transactions';

function* fetchOptionsData(props) {
  const {
    globalActions: { fetchDictionaries }
  } = props;
  const optionsResources = getOptionsDataResources(colDef);

  yield put(fetchDictionaries(optionsResources));
}

function* deleteTransactionHandler(props, { payload: { id, callback: refreshGrid } }) {
  const {
    globalActions: { enqueueSnackbar }
  } = props;

  try {
    yield call(apiMethods.delete, `${RESOURCE}/${id}`);

    yield put(
      enqueueSnackbar({
        message: 'Transaction successfully deleted!',
        options: { variant: 'success' }
      })
    );

    refreshGrid();
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  }
}

function* saveTransactionHandler(props, { payload: { data, callback: refreshCallback } }) {
  const {
    globalActions: { enqueueSnackbar }
  } = props;

  const { id } = data;
  const method = id ? 'update' : 'create';
  const url = `${RESOURCE}/${id || ''}`;

  try {
    yield call(apiMethods[method], url, { data });

    yield put(
      enqueueSnackbar({
        message: 'Transaction successfully saved!',
        options: { variant: 'success' }
      })
    );
    refreshCallback();
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  } finally {
    yield put(setInitItemData());
  }
}

function* fetchTransactionHandler(props, { payload: id }) {
  const {
    globalActions: { enqueueSnackbar }
  } = props;

  if (id) {
    try {
      const { data } = yield call(apiMethods.get, `${RESOURCE}/${id}`);

      yield put(setItem(data));
    } catch (error) {
      yield put(enqueueSnackbar({
        message: error.toString(),
        options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
      }))
    }
  } else {
    yield put(setInitItemData());
  }
}

function* fetchTransactionItems(props) {
  const {
    globalActions: { enqueueSnackbar, setLoading }
  } = props;

  yield put(setLoading(true));
  try {
    const { data } = yield call(apiMethods.get, `/transactions/transactionItems`);

    yield put(setTransactionItems(data));
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  } finally {
    yield put(setLoading(false));
  }
}

export default function* transactionsSagaWatcher(props) {
  yield call(fetchOptionsData, props);
  yield call(fetchTransactionItems, props);

  yield takeLatest(SAVE_TRANSACTION, saveTransactionHandler, props);
  yield takeLatest(DELETE_TRANSACTION, deleteTransactionHandler, props);
  yield takeLatest(LOAD_ITEM, fetchTransactionHandler, props);
}
