const DO = {
  clients: ['all'],
  projects: ['all'],
  timesheets: ['all'],
  invoices: ['all'],
  userrequests: ['all'],
  invoicesnew: ['all'],
  tasks: ['all'],
  assets: ['show', 'read'],
  assetitems: ['show', 'read'],
  people: ['show', 'read'],
  departments: ['show', 'read'],
  periodsalaries: ['show', 'read'],
  periods: ['show', 'read'],
  dictionary: ['read'],
  locations: ['read'],
  expensecategories: ['read'],
  depreciationrules: ['read'],
  vendors: ['read'],
  billingtypes: ['read'],
  billingrules: ['read'],
  depreciationcalculation: ['read'],
  earnings: ['read'],
  expenses: ['read'],
  importmappings: ['read'],
  import: ['all'],
  moneytypes: ['read'],
  moneys: ['read'],
  payables: ['read'],
  ptos: ['read'],
  receivables: ['read'],
  reimbursements: ['read'],
  salaries: ['read'],
  transactiontypes: ['read'],
  transactions: ['read'],
  transactionsources: ['read'],

  // billing types: ['show', 'read'],
  // depreciation calculation: ['show', 'read'],
  // earnings: ['show', 'read'],
  // expenses: ['show', 'read'],
  // import mappings: ['show', 'read'],
  // import salaries: ['show', 'read'],
  // import timesheets: ['show', 'read'],
  // import transactions: ['show', 'read'],
  // money types: ['show', 'read'],
  // moneys: ['show', 'read'],
  // payables: ['show', 'read'],
  // ptos: ['show', 'read'],
  // receivables: ['show', 'read'],
  // reimbursements: ['show', 'read'],
  // salaries: ['show', 'read'],
  // transaction types: ['show', 'read'],
  // transactions: ['show', 'read'],
  auditlog: ['all']
};
const Accountant = {
  assets: ['read'], // only for their location
  people: ['show', 'read'], // only for their location
  vendors: ['all'], // only for their location
  transactions: ['read'], // only for their location
  timesheets: ['show', 'read'], // only for their location
  locations: ['show', 'read'], // only for their location
  periodsalaries: ['all'], // only for their location
  invoices: ['read'], // only for their location
  import: ['all'],
  billingrules: ['read'],
  billingtypes: ['read'],
  dictionary: ['read'],
  departments: ['show', 'read'],
  depreciationrules: ['all'],
  expensecategories: ['all'],
  projects: ['read'],
  moneytypes: ['all'],
  periods: ['show', 'read'],
  clients: ['show', 'read'],
  assetitems: ['show', 'read'],
  invoicetemplates: ['all'],
  calculation: ['read'],
  earnings: ['read'],
  expenses: ['read'],
  importmappings: ['read'],
  moneys: ['read'],
  payables: ['read'],
  ptos: ['read'],
  receivables: ['read'],
  reimbursements: ['read'],
  salaries: ['read'],
  transactionsources: ['read'],
  transactiontypes: ['read'],
  invoicesnew: ['all'],
  userrequests: ['all'],
  tasks: ['show', 'read'], // only for their location
  settings: ['read'],
  auditlog: ['all']
};
const HR = {
  assets: ['show', 'read'], // only for their location
  people: ['all'], // only for their location
  vendors: ['all'], // only for their location
  timesheets: ['all'], // only for their location
  clients: ['read'],
  locations: ['all'],
  billingrules: ['show', 'read'],
  departments: ['show', 'read'],
  dictionary: ['read'],
  depreciationrules: ['read'],
  expensecategories: ['read'],
  projects: ['show', 'read'],
  periodsalaries: ['show', 'read'],
  billingtypes: ['read'],
  assetitems: ['show', 'read'],
  invoicetemplates: ['all'],
  periods: ['show', 'read'],

  calculation: ['read'],
  earnings: ['read'],
  expenses: ['read'],
  import: ['read'],
  moneys: ['read'],
  payables: ['read'],
  ptos: ['read'],
  receivables: ['read'],
  reimbursements: ['read'],
  salaries: ['read'],
  userrequests: ['all'],
  tasks: ['all'],
  settings: ['read'],
  auditlog: ['all']
};

const Admin = {
  'assets': ['all'],
  'clients': ['all'],
  'people': ['all'],
  'locations': ['all'],
  'departments': ['all'],
  'depreciationrules': ['all'],
  'expensecategories': ['all'],
  'projects': ['all'],
  'vendors': ['all'],
  'moneytypes': ['all'],
  'dictionary': ['read'],
  'periods': ['all'],
  'timesheets': ['all'],
  'import': ['all'],
  'salaries': ['all'],

  'assetitems': ['all'],
  'billingrules': ['all'],
  'billingtypes': ['all'],

  'invoicetemplates': ['all'],
  'calculation': ['all'],
  'earnings': ['all'],
  'expenses': ['all'],
  'periodsalaries': ['all'],
  'moneys': ['all'],
  'payables': ['all'],
  'ptos': ['all'],
  'receivables': ['all'],
  'reimbursements': ['all'],
  'transactiontypes': ['all'],
  'transactionsources': ['all'],
  'transactions': ['all'],
  'invoices': ['all'],
  'invoicesnew': ['all'],
  'periodclose': ['all'],
  'userrequests': ['all'],
  'tasks': ['all'],
  'settings': ['read'],
  'calculate/expense': ['all'],
  'calculate/salary': ['all'],
  'calculate/depreciation': ['all'],
  'auditlog': ['all']
};

export default {
  DO,
  Accountant,
  Admin,
  HR
};
