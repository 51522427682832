import { Box, Button, Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import React from 'react';
import LoadingScreen from '../LoadingScreen';
import GridAuditLog from '../GridAuditLog/GridAuditLog'
import colDefs from './colDef'

const AuditLogModal = ({open,closeAuditLog,isLoad,auditLog}) => {
    return (
        <Dialog open={open} fullWidth maxWidth='md' >
        <DialogTitle>Audit log</DialogTitle>
            <DialogContent >
                {!isLoad && <LoadingScreen />}
                {isLoad && <GridAuditLog 
                    auditLogData={auditLog}
                    colDefs={colDefs}
                    formMaxWidth="lg"
                
                />}
            </DialogContent>
            <Box display={'flex'} justifyContent={'flex-end'} padding={'8px'}>
                <Button onClick={closeAuditLog}>Close</Button>
            </Box>
    </Dialog>
    );
};

export default AuditLogModal;