import { SET_ACTIVE_PERIOD } from './rootActions/actionTypes';

const initialState = {
  activePeriod: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ACTIVE_PERIOD:
      return {
        ...state,
        activePeriod: payload
      };

    default:
      return state;
  }
}
