import {
  SET_PROJECTS_LIST,
  CREATE_PROJECT,
  REMOVE_PROJECT,
  SAVE_PROJECT,

  CHANGE_HANDLER,

  // billing rules

  SET_BILLING_RULES_LIST,
  SET_LOAD_BILLING_RULES_LIST,
  CREATE_BILLING_RULE,
  REMOVE_BILLING_RULE,
  SAVE_BILLING_RULE,
  FETCH_BILLING_RULES,

  // tasks

  SET_TASKS_LIST,
  SET_LOAD_TASKS_LIST,
  CREATE_TASK,
  REMOVE_TASK,
  SAVE_TASK,
  FETCH_TASKS_LIST, SET_LOAD_PROJECTS_LIST,
} from './actionTypes';


export const setProjectsList = projects => ({
  type: SET_PROJECTS_LIST,
  payload: projects
});

export const setLoadProjectsList= isLoad => ({
  type: SET_LOAD_PROJECTS_LIST,
  payload: isLoad
});

export const createProject = () => ({
  type: CREATE_PROJECT
});

export const removeProject = id => ({
  type: REMOVE_PROJECT,
  payload: id
});

export const saveProject = data => ({
  type: SAVE_PROJECT,
  payload: data
});

export const changeHandler = data => ({
  type: CHANGE_HANDLER,
  payload: {hasChanges: data}
});

// billing rules

export const setBillingRulesList = billingRules => ({
  type: SET_BILLING_RULES_LIST,
  payload: billingRules
});

export const setLoadBillingRulesList = isLoad => ({
  type: SET_LOAD_BILLING_RULES_LIST,
  payload: isLoad
});

export const createBillingRule = () => ({
  type: CREATE_BILLING_RULE
});

export const removeBillingRule = rule => ({
  type: REMOVE_BILLING_RULE,
  payload: rule
});

export const saveBillingRule = data => ({
  type: SAVE_BILLING_RULE,
  payload: data
});

export const fetchCurrentBillingRules = id => ({
  type: FETCH_BILLING_RULES,
  payload: id
});

// tasks

export const setTasksList = tasks => ({
  type: SET_TASKS_LIST,
  payload: tasks
});

export const setLoadTasksList = isLoad => ({
  type: SET_LOAD_TASKS_LIST,
  payload: isLoad
});

export const createTask = () => ({
  type: CREATE_TASK,
});

export const removeTask = task => ({
  type: REMOVE_TASK,
  payload: task
});

export const saveTask = data => ({
  type: SAVE_TASK,
  payload: data
});

export const fetchCurrentTasks = id => ({
  type: FETCH_TASKS_LIST,
  payload: id
});

