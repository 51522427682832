import { call, put, takeLatest } from "redux-saga/effects";
import {IMPORT_FROM_DB, UPLOAD_DATA} from './actions/actionTypes';
import apiMethods from "src/api/apiMethods";
import { translate } from 'src/utils';

function* fetchOptionsData(props) {
  const { globalActions: { fetchDictionaries } } = props;
  const sources = ['transactionSources'];

  yield put(fetchDictionaries(sources));
}

function* uploadDataHandler(props, { payload: { data: { file, ...params }, resource } }) {
  const { globalActions: { enqueueSnackbar } } = props;
  const blobFile = new Blob([file], { type: 'text/csv' })
  const formData = new FormData();
  yield formData.append('file', blobFile, file.name);
  yield Object.keys(params).forEach(key => formData.append(key, params[key]))

  try {
    yield call(apiMethods.create, `import/${resource}`, { data: formData });

    yield put(enqueueSnackbar({
      message: translate(`views.import.${resource}.success`),
      options: { variant: 'success' }
    }))
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))

  }

}

function* importFromDBHandler(props) {
  const { globalActions: { enqueueSnackbar } } = props;

  try {
    yield call(apiMethods.get, '/import/timesheetsFromDb');

    yield put(enqueueSnackbar({
      message: translate('views.import.timesheets.success'),
      options: { variant: 'success' }
    }))
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))

  }

}

export default function* importsSagaWatcher(props) {
  yield call(fetchOptionsData, props);

  yield takeLatest(UPLOAD_DATA, uploadDataHandler, props);
  yield takeLatest(IMPORT_FROM_DB, importFromDBHandler, props);
}
