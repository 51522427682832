import React, { createContext, useEffect, useReducer } from 'react';
import { Auth } from 'aws-amplify';
import SplashScreen from 'src/components/SplashScreen';
import awsConfig from 'src/config';
import { get } from 'lodash';
import {getPermissions, getRole, visible} from 'src/utils';
import { setToken } from '../api/http-client';
import { useLocation } from "react-router-dom";

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'aws',
  login: () => Promise.resolve(),
  logout: () => Auth.signOut(),
});

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const {pathname} = useLocation();

  const login = async options => {};

  const logout = () => {
    Auth.signOut();

    localStorage.removeItem('token');

    dispatch({
      type: 'LOGOUT'
    });

  };

  const isPermiss = (currencyPathname = pathname) => {
    return visible(currencyPathname, state.user.permissions)
  }

  useEffect(() => {
    const initialise = async () => {
      try {
        await Auth.configure(awsConfig);

        const response = await Auth.currentAuthenticatedUser();
        const token = get(response, 'signInUserSession.idToken.jwtToken', null);
        const groups = get(response, 'signInUserSession.idToken.payload.cognito:groups', null);
        const { sub: id, email } = get(response, 'signInUserSession.idToken.payload');

        const isAuthenticated = token;

        if (isAuthenticated) {
          setToken(token);
          localStorage.setItem('token', token);

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated,
              user: {
                id,
                email,
                permissions: getPermissions(getRole(groups)),
                tier: getRole(groups)
              }
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'aws',
        login,
        logout,
        isPermiss,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
