import { all, call, put, select } from 'redux-saga/effects';
import { has, get, uniq } from 'lodash';
import apiMethods from 'src/api/apiMethods';
import { setDictionaries } from './actions';

export default function* dictionariesSaga(props, { payload: dictionaries }) {
  const { globalActions: { setLoading } } = props;
  const loadedDictionaries = yield select(state => state.dictionaries);
  const names = uniq(dictionaries).filter(name => !has(loadedDictionaries, name));

  yield put(setLoading(true));

  try {
    const effects = names.map(name => call(apiMethods.get, `dictionary/${name}`));
    const loadedData = yield all(effects);
    const data = names.reduce((acc, name, index) => ({
      ...acc,
      [name]: get(loadedData, [index, 'data'], [])
    }), {});

    yield put(setDictionaries(data));

  } catch (error) {
    // TODO: show error notification
    console.log(error);
  } finally {
    yield put(setLoading(false));
  }
}
