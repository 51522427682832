import {
  LOAD_ITEM,
  SET_ITEM,
  SAVE,
  REMOVE,
  CHANGE_HANDLER,
  PREVIEW_INVOICE_PDF,
  SEND_TO_CLIENT,
  SET_CURRENT_PROJECTS,
  FETCH_PROJECTS,
  SET_LOAD,
  SAT_TIME_SHEETS_LIST,
  FETCH_CURRENT_INVOICE_TEMPLATES,
  SET_CURRENT_INVOICE_TEMPLATES
} from './actionTypes';

export const loadItem = id => ({
  type: LOAD_ITEM,
  payload: id
});

export const setItem = data => ({
  type: SET_ITEM,
  payload: data
});

export const save = (data, callback) => ({
 type: SAVE,
 payload: { data, callback }
});

export const remove = (id, callback) => ({
  type: REMOVE,
  payload: { id, callback }
});

export const changeHandler = data => ({
  type: CHANGE_HANDLER,
  payload: data
});

export const previewInvoicePdf = id => ({
  type: PREVIEW_INVOICE_PDF,
  payload: id
})

export const sendToClient = id => ({
  type: SEND_TO_CLIENT,
  payload: id
})

export const setCurrentProjects = (data) => ({
  type: SET_CURRENT_PROJECTS,
  payload: data
})

export const setTimeSheets = (data) => ({
  type: SAT_TIME_SHEETS_LIST,
  payload: data
})

export const fetchProjects = ({id, resource}) => ({
  type: FETCH_PROJECTS,
  payload: {id, resource}
})

export const setLoad = (field, value) => ({
  type: SET_LOAD,
  payload: {field, value}
})

export const setCurrentInvoiceTemplates = data => ({
  type: SET_CURRENT_INVOICE_TEMPLATES,
  payload: data
})

export const fetchCurrentInvoiceTemplates = id => ({
  type: FETCH_CURRENT_INVOICE_TEMPLATES,
  payload: id
})
