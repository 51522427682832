import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { AgGridReact } from '@ag-grid-community/react';
import { withSnackbar } from 'notistack';
import { Box,  } from '@material-ui/core';
import Form from 'src/components/Form';

import {doNoting } from 'src/utils';


class GridAuditLog extends Component {
  static propTypes = {
    colDefs: PropTypes.array.isRequired,
    optionsData: PropTypes.object,
    resource: PropTypes.string,
    auditLogData:PropTypes.array,
    rowClassRules: PropTypes.object,
    formMaxWidth: PropTypes.string,
    addLabel: PropTypes.string,
    actions: PropTypes.node,
    onSave: PropTypes.func,
    onItemRemove: PropTypes.func,
    onItemLoad: PropTypes.func,
    itemFetching: PropTypes.bool,
    itemData: PropTypes.object,
    FormComponent: PropTypes.elementType,
    formActions: PropTypes.elementType,
    dialogClasses: PropTypes.object,
    tier:PropTypes.string,
    refreshAction: PropTypes.func
  };

  static defaultProps = {
    auditLogData: [],
    pageTitle: 'New page',
    title: 'Untitled Items',
    rowClassRules: {},
    formMaxWidth: 'sm',
    addLabel: 'Add new',
    actions: null,
    onSave: doNoting,
    onItemRemove: doNoting,
    onItemLoad: doNoting,
    itemFetching: false,
    itemData: {},
    dialogClasses: {},
    FormComponent: Form,
    tier: '',
    refreshAction: doNoting,
  }

  constructor(props) {
    super(props);

    this.gridApi = null;
    this.columnApi = null;

   

    this.state = {
      isModalOpen: false,
      formData: {},
      canSave: false
    };
  }

  get columnDefs() {
    const { colDefs } = this.props;
    
    
    return colDefs.map(({ type, ...col }) => {
      switch (type) {
        case 'boolean':
          return {
            filter: 'dropDownFilter',
            floatingFilter: true,
            floatingFilterComponent: 'dropDownFloatingFilter',
            cellEditor: 'booleanCellEditor',
            cellRenderer: 'booleanCellRenderer',
            ...col
          };
        case 'text':
          return {
            filter: 'agTextColumnFilter',
            sortable: true,
            cellEditor: 'textCellEditor',
            
            ...col
          };
        case 'dropdown':
          return {
            cellEditor: 'selectCellEditor',
            cellRenderer: 'dropDownCellRenderer',
            filter: 'dropDownFilter',
            floatingFilterComponent: 'dropDownFloatingFilter',
            cellEditorParams: {
              ...col.cellEditorParams
            },
            minWidth: 180,
            ...col
          };
        case 'date':
          return {
            field: 'date',
            filter: 'agDateColumnFilter',
            minWidth: 180,
            ...col
          };

        default:
          return col;
      }
    })
  }
  render() {
    const {auditLogData} = this.props;
    const Data = auditLogData.map(item => ({
      action:item.action,
      timestamp:item.timestamp,
      userEmail:item.userEmail,
      currentValues:JSON.stringify(item.currentValues.data, null, ""),
      previousValues:JSON.stringify(item.previousValues, null, "")
    })).reverse()
    
    return (
        <Box mt={3} className="ag-theme-balham" style={{height:300}}>
          <AgGridReact
                rowData={Data}
                modules={AllCommunityModules}
                columnDefs={this.columnDefs}
                enableBrowserTooltips 
                
                >
            </AgGridReact>
        </Box>
    );
  };
}

export default withSnackbar(GridAuditLog);


