import { SET_AUDITLOG,LOAD_AUDITLOG} from './actions/actionTypes';

const initialState = {
  isLoad:false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_AUDITLOG:
      return {
        ...state,
        ...payload
      };
      case LOAD_AUDITLOG:
        return {
        ...state,isLoad:payload
        }
    default:
      return state;
  }
};
