import { LOAD_ITEM, SET_ITEM, SAVE_PERSON, DELETE_PERSON, SET_PEOPLE_LOADING } from './actionTypes';

export const loadItem = id => ({
  type: LOAD_ITEM,
  payload: id
});

export const setItem = data => ({
  type: SET_ITEM,
  payload: data
});

export const savePerson = (data, callback) => ({
 type: SAVE_PERSON,
 payload: { data, callback }
});

export const deletePerson = (id, callback) => ({
  type: DELETE_PERSON,
  payload: { id, callback }
});

export const setPeopleLoading = status => ({
  type: SET_PEOPLE_LOADING,
  payload: status
});
