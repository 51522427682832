import { SET_DICTIONARIES, FETCH_DICTIONARIES } from './actionTypes';

export const setDictionaries = data => ({
  type: SET_DICTIONARIES,
  payload: data
});

export const fetchDictionaries = dictionaries => ({
  type: FETCH_DICTIONARIES,
  payload: dictionaries
});
