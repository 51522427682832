import { translate } from 'src/utils';

export default [
  {
    field: 'id',
    colId: 'id',
    headerName: translate('views.people.fields.id'),
    cellRenderer: 'loadingRenderer',
    editable: false,
    minWidth: 80,
    maxWidth: 80,
    filter: 'agNumberColumnFilter',
    floatingFilterComponentParams: { suppressMenu: false, suppressFilterButton: false },
  },
  {
    field: 'firstName',
    colId: 'firstName',
    type: 'text'
  },
  {
    field: 'lastName',
    colId: 'lastName',
    type: 'text'
  },
  {
    field: 'otherFirstName',
    colId: 'otherFirstName',
    type: 'text',
    minWidth: 130
  },
  {
    field: 'otherLastName',
    colId: 'otherLastName',
    type: 'text',
    minWidth: 130
  },
  {
    field: 'account',
    colId: 'account',
    type: 'text'
  },
  {
    field: 'trackPto',
    colId: 'trackPto',
    type: 'boolean'
  },
  {
    field: 'active',
    colId: 'active',
    type: 'boolean'
  },
  {
    field: 'locations',
    colId: 'locationId',
    type: 'dropdown',
    optionsAccessor: 'locations'
  },
  {
    field: 'departments',
    colId: 'departmentId',
    type: 'dropdown',
    optionsAccessor: 'departments',
    minWidth: 180
  },
  {
    field: 'immediatePtoExpense',
    colId: 'immediatePtoExpense',
    type: 'boolean',
    headerName: 'Imm PTO Exp',
  },
];
