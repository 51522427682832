import {SET_AUDITLOG,FETCH_AUDITLOG,LOAD_AUDITLOG} from './actionTypes';

export const setAuditlog = data => ({
  type: SET_AUDITLOG,
  payload: data
});

export const fetchAuditlog = (entityName, entityId) => ({
  type: FETCH_AUDITLOG,
  payload: {entityName, entityId}
}); 

export const loadAuditLog = (status)=> ({
  type:LOAD_AUDITLOG,
  payload:status
})



