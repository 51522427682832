import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import {
  Text,
  Select,
  Switch,
  Date,
  Empty,
  InvoiceProject,
  NumberField
} from '../FieldTypes';

const renderControl = (type, { parentField, ...props }) => {
  switch (type) {
    case 'text':
      return <Text {...props} />;
    case 'number':
      return <NumberField {...props} />;
    case 'select':
      return <Select {...props} />;
    case 'switch':
      return <Switch type={type} {...props}/>;
    case 'date':
      return <Date {...props} />;
    case 'invoiceProject':
      return <InvoiceProject {...props} />;
    default:
      return <Empty {...props} />;
  }
};

const Field = props => {
  const { col, type = 'empty', config, name, value, dependency, formData} = props;

  const dependentField = config.find(field => field.parentField === name)

  return (
    <Grid item xs={dependentField  && !value ? 12 : col}>
      {renderControl(type, {...props, disabled: !!dependency && !formData[dependency]})}
    </Grid>
  );
};

Field.propTypes = {
  col: PropTypes.number,
  type: PropTypes.string
};

Field.defaultProps = {
  col: 12,
  type: 'empty'
};

export default Field;
