import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import createReducer from './reducer';
import rootSaga from './rootSaga';
import * as globalActions from './rootActions';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (preloadedState = {}) => {
  return createStore(
    createReducer(history), // root reducer with router state
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        sagaMiddleware
      )
    )
  );
};

export default configureStore();

sagaMiddleware.run(rootSaga, {
  globalActions
});

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();
