import React, { createContext, useState } from 'react';
import { connect } from 'react-redux';
import { fetchAuditlog } from 'src/store/rootActions';
import AuditLogModal from '../../components/AuditLogModal/AuditLogModal';
import { loadAuditLog } from './actions';

const AuditLogContext = createContext({});

const Provider = ({ children, isLoad, auditLog, fetchAuditlog, loadAuditLog }) => {
  const [open, setOpen] = useState(false);
  const openAuditLog = (entity, id) => {
    setOpen(true);
    fetchAuditlog(entity, id);
  };
  const closeAuditLog = isLoad => {
    setOpen(false);
    loadAuditLog(isLoad);
  };

  return (
    <AuditLogContext.Provider value={{ openAuditLog }}>
      {children}
      <AuditLogModal
        open={open}
        closeAuditLog={closeAuditLog}
        isLoad={isLoad}
        auditLog={auditLog}
      />
    </AuditLogContext.Provider>
  );
};

const mapStateToProps = state => ({
  auditLog: state.auditLog.data,
  isLoad: state.auditLog.isLoad
});

const mapDispatchToProps = dispatch => ({
  fetchAuditlog: (entity, id) => dispatch(fetchAuditlog(entity, id)),
  loadAuditLog: isLoad => dispatch(loadAuditLog(!isLoad))
});

const AuditLogProvider = connect(mapStateToProps, mapDispatchToProps)(Provider);

export { AuditLogContext, AuditLogProvider };
