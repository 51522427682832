import {call, put, takeLatest} from 'redux-saga/effects';
import colDef from './colDef';
import {getOptionsDataResources} from 'src/utils/utils';
import {LOAD_ITEM, SAVE, REMOVE, BULK_REMOVE} from './actions/actionTypes';
import apiMethods from 'src/api/apiMethods';
import {setItem} from './actions';

function* fetchOptionsData(props) {
  const {globalActions: {fetchDictionaries}} = props;
  const optionsResources = getOptionsDataResources(colDef);
  optionsResources.push('tasks')
  yield put(fetchDictionaries(optionsResources));
}

function* removeHandler(props, {payload: {id, callback: refreshGrid}}) {
  const {globalActions: {enqueueSnackbar}} = props;

  try {
    yield call(apiMethods.delete, `timeSheets/${id}`);

    yield put(enqueueSnackbar({
      message: 'Successfully delete!',
      options: {variant: 'success'},
    }));

    yield refreshGrid()

  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString() || 'Rule not deleted!',
      options: {variant: 'error', style: {whiteSpace: 'pre-line'}}
    }))
  }
}

function* saveHandler(props, {payload: {data, callback: refreshGrid}}) {
  const {globalActions: {enqueueSnackbar}} = props;
  const {id} = data;
  const method = id ? 'update' : 'create';
  const url = id ? `timeSheets/${id}` : 'timeSheets';

  try {
    yield call(apiMethods[method], url, {data})

    yield put(enqueueSnackbar({
      message: 'Successfully saved!',
      options: {variant: 'success'},
    }));

    refreshGrid();
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: {variant: 'error', style: {whiteSpace: 'pre-line'}}
    }))
  }
}

function* bulkRemoveHandler(props, {payload: refreshGrid}) {
  const {globalActions: {enqueueSnackbar}} = props;

  try {
    yield call(apiMethods.delete, 'timeSheets');

    yield put(enqueueSnackbar({
      message: 'Successfully delete!',
      options: {variant: 'success'},
    }));

    refreshGrid();
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: {variant: 'error', style: {whiteSpace: 'pre-line'}}
    }))
  }
}

function* loadItemHandler(props, {payload: id}) {
  const {globalActions: {enqueueSnackbar}} = props;
  try {
    if (id) {
      const {data} = yield call(apiMethods.get, `timeSheets/${id}`);

      yield put(setItem(data));
    } else {
      yield put(setItem({}));
    }
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: {variant: 'error', style: {whiteSpace: 'pre-line'}}
    }))
  }
}

export default function* timeSheetsSagaWatcher(props) {
  yield call(fetchOptionsData, props);

  yield takeLatest(SAVE, saveHandler, props);
  yield takeLatest(REMOVE, removeHandler, props);
  yield takeLatest(BULK_REMOVE, bulkRemoveHandler, props);
  yield takeLatest(LOAD_ITEM, loadItemHandler, props);
}
