import { call, put, select, takeLatest } from 'redux-saga/effects';
import colDef from './colDef';
import { getOptionsDataResources } from 'src/utils/utils';
import { LOAD_ITEM, SAVE, REMOVE, BULK_REMOVE, ADD_TRANSACTIONS, CLEAN_TRANSACTIONS, CLEAN_ADD_TRANSACTIONS } from './actions/actionTypes';
import apiMethods from 'src/api/apiMethods';
import { setItem  } from './actions';

function* fetchOptionsData(props) {
  const { globalActions: { fetchDictionaries } } = props;
  const optionsResources = getOptionsDataResources(colDef);

  yield put(fetchDictionaries(optionsResources));
}

function* removeHandler(props, { payload: { id, callback: refreshGrid } }) {
  const { globalActions: { enqueueSnackbar } } = props;

  try {
    yield call(apiMethods.delete, `periodSalaries/${id}`);

    yield put(enqueueSnackbar({
      message: 'PeriodSalary successfully deleted!',
      options: { variant: 'success' }
    }))

    refreshGrid();
  } catch (error) {

    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  }
}

function* saveHandler(props, { payload: { data, callback: refreshGrid } }) {
  const { globalActions: { enqueueSnackbar } } = props;
  const { id } = data;
  const method = id ? 'update' : 'create';
  const url = `periodSalaries/${id ? id : ''}`;

  try {
    yield call(apiMethods[method], url, { data });

    yield put(enqueueSnackbar({
      message: 'Successfully saved!',
      options: { variant: 'success' },
    }));

    refreshGrid();
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  }
}

function* bulkRemoveHandler(props, {payload: refreshGrid}) {
  const { globalActions: { enqueueSnackbar } } = props;
  try {
    yield call(apiMethods.delete, 'periodSalaries');

    yield put(enqueueSnackbar({
      message: 'successfully delete!',
      options: { variant: 'success' },
    }));

    refreshGrid();
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  }
}

function* removeDepreciation() {
  const {start, end} = yield select(state => state.root.activePeriod);


  yield call(apiMethods.delete, 'transactions', { filter: { source: 'salary' , date: { between: [start, end] } } });
}

function* addTransactions() {
  yield call(apiMethods.create, 'calculate/salary');
}

function* addTransactionsHandler(props) {
  const { globalActions: { enqueueSnackbar } } = props;

  try {
    yield call(addTransactions);

    yield put(enqueueSnackbar({
      message: 'successfully calculate!',
      options: { variant: 'success' },
    }));
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  }
}

function* removeSalaryHandler(props) {
  const { globalActions: { enqueueSnackbar } } = props;

  try {
    yield call(removeDepreciation, props);

    yield put(enqueueSnackbar({
      message: 'successfully delete!',
      options: { variant: 'success' },
    }));
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  }
}

function* removeAddSalaryHandler(props) {
  const { globalActions: { enqueueSnackbar } } = props;

  try {
    yield call(removeDepreciation, props);
    yield call(addTransactions, props);

    yield put(enqueueSnackbar({
      message: 'successfully calculate!',
      options: { variant: 'success' },
    }));
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  }
}

function* loadItemHandler(props, { payload: id }) {
  const { globalActions: { enqueueSnackbar } } = props;

  try {
    if (id) {
      const { data } = yield call(apiMethods.get, `periodSalaries/${id}`);

      yield put(setItem(data));
    } else {
      yield put(setItem({}));
    }
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  }
}

export default function* periodSalariesSagaWatcher(props) {
  yield call(fetchOptionsData, props);

  yield takeLatest(SAVE, saveHandler, props);
  yield takeLatest(REMOVE, removeHandler, props);
  yield takeLatest(BULK_REMOVE, bulkRemoveHandler, props);
  yield takeLatest(ADD_TRANSACTIONS, addTransactionsHandler, props);
  yield takeLatest(CLEAN_TRANSACTIONS, removeSalaryHandler, props);
  yield takeLatest(CLEAN_ADD_TRANSACTIONS, removeAddSalaryHandler, props);
  yield takeLatest(LOAD_ITEM, loadItemHandler, props);
}
