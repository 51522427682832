import {call, put, takeLatest} from 'redux-saga/effects';
import colDef from './colDef';
import {getOptionsDataResources} from 'src/utils/utils';
import apiMethods from 'src/api/apiMethods';
import {
  LOAD_ITEM,
  SAVE,
  REMOVE,
  FETCH_PROJECTS,
  PREVIEW_INVOICE_PDF,
  SEND_TO_CLIENT,
  FETCH_CURRENT_INVOICE_TEMPLATES
} from './actions/actionTypes';
import {setCurrentInvoiceTemplates, setCurrentProjects, setItem, setLoad, setTimeSheets} from './actions';
import {initialItemState} from "./reducer";

const resource = 'invoicesNew'

function* fetchOptionsData(props) {
  const {globalActions: {fetchDictionaries}} = props;
  const optionsResources = getOptionsDataResources(colDef);
  optionsResources.push(...['projects', 'billingRules', 'invoiceTemplates', 'people', 'departments']);
  yield put(fetchDictionaries(optionsResources));
}

function* removeHandler(props, {payload: {id, callback: refreshGrid}}) {
  const {globalActions: {enqueueSnackbar}} = props;

  try {
    yield call(apiMethods.delete, `${resource}/${id}`);

    yield put(enqueueSnackbar({
      message: 'Successfully delete!',
      options: {variant: 'success'},
    }));

    refreshGrid();
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  }
}

function* saveHandler(props, {payload: {data, callback: refreshGrid}}) {
  const {globalActions: {enqueueSnackbar}} = props;
  const {id} = data;
  const method = id ? 'update' : 'create';
  const url = `${resource}/${id ? id : ''}`;

  try {
    yield call(apiMethods[method], url, {data});

    yield put(enqueueSnackbar({
      message: 'Successfully saved!',
      options: {variant: 'success'},
    }));

    refreshGrid();
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  }
}

function* loadItemHandler(props, {payload: id}) {
  const {globalActions: {enqueueSnackbar}} = props;
  yield put(setLoad("item", true))
  try {
    if (id) {
      const {data} = yield call(apiMethods.get, `${resource}/${id}`);
      yield put(setItem(data));
    } else {
      yield put(setItem({...initialItemState}));
    }
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  }
}

function* fetchCurrentProjects(props, {payload: {id, resource}}) {
  const {globalActions: {enqueueSnackbar}} = props;
  yield put(setLoad('currentProjects', false))
  try {
    if (id) {
      if (resource === 'projects') {
        const {data} = yield call(apiMethods.get, `${resource}`, {filter: {clientId: id, active: true}});
        yield put(setCurrentProjects(data));

      } else {
        const {data} = yield call(apiMethods.get, `${resource}/${id}`);
        yield put(setCurrentProjects(data.projects));
      }
    } else {
      yield put(setCurrentProjects([]));
    }

    const {data} = yield call(apiMethods.get, `timeSheets`);
    yield put(setTimeSheets(data));

  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  } finally {
    yield setLoad('currentProjects', true)
  }
}

function* previewInvoicePDF(props, {payload: id}) {
  const {globalActions: {enqueueSnackbar}} = props;
  try {
    if (id) {
      const data = yield call(apiMethods.get, `${resource}/pdf/${id}`);
      var file = yield new Blob([Buffer.from(data, 'base64')], {type: 'application/pdf'});
      var fileURL = yield window.URL.createObjectURL(file);
      yield window.open(fileURL);
      }
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  } finally {
  }
}

function* sendToClient(props, {payload: id}) {
  const {globalActions: {enqueueSnackbar}} = props;
  try {
    if (id) {
      yield call(apiMethods.get, `${resource}/sendpdf/${id}`);
      yield call(loadItemHandler, props, {payload: id})
      yield put(enqueueSnackbar({
        message: 'Invoice sended',
        options: {variant: 'success'},
      }));
    }
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  } finally {
  }
}


function* fetchCurrentInvoiceTemplates(props, {payload: id}) {
  const {globalActions: {enqueueSnackbar}} = props;
  yield put(setLoad('invoiceTemplates', false))
  yield put(setCurrentInvoiceTemplates([]));
  try {
    if (id) {
        const {data} = yield call(apiMethods.get, `invoiceTemplates`, {filter: {clientId: id}});
        yield put(setCurrentInvoiceTemplates(data));
    }
  } catch (error) {
    yield put(enqueueSnackbar({
      message: error.toString(),
      options: { variant: 'error',  style: { whiteSpace: 'pre-line' } }
    }))
  } finally {
    yield setLoad('invoiceTemplates', true)
  }
}

export default function* invoicesSagaWatcher(props) {
  yield call(fetchOptionsData, props);

  yield takeLatest(SAVE, saveHandler, props);
  yield takeLatest(REMOVE, removeHandler, props);
  yield takeLatest(LOAD_ITEM, loadItemHandler, props);
  yield takeLatest(FETCH_PROJECTS, fetchCurrentProjects, props);
  yield takeLatest(PREVIEW_INVOICE_PDF, previewInvoicePDF, props);
  yield takeLatest(SEND_TO_CLIENT, sendToClient, props);
  yield takeLatest(FETCH_CURRENT_INVOICE_TEMPLATES, fetchCurrentInvoiceTemplates, props);
}
