import {
  SET_BILLING_RULES_LIST,
  SET_PROJECTS_LIST,
  CHANGE_HANDLER,
  SET_TASKS_LIST,
  SET_LOAD_BILLING_RULES_LIST,
  SET_LOAD_TASKS_LIST,
  SET_LOAD_PROJECTS_LIST
} from './actions/actionTypes';

const initialState = {
  total: 0,
  list: [],
  rules: [],
  tasks: [],
  hasChanges: false,
  isFetched: {}
};


export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PROJECTS_LIST:
      const { data, total } = payload;
      return {
        ...state,
        list: data,
        total,
        isFetched: {...state.isFetched, projects: true}
      };
    case SET_LOAD_PROJECTS_LIST:
      return {
        ...state,
        isFetched: {...state.isFetched, projects: payload}
      };
    case SET_BILLING_RULES_LIST:
      return {
        ...state,
        rules: payload,
        isFetched: {...state.isFetched, rules: true}
      };
    case SET_LOAD_BILLING_RULES_LIST:
      return {
        ...state,
        isFetched: {...state.isFetched, rules: payload}
      };
    case SET_TASKS_LIST:
      return {
        ...state,
        tasks: payload.data,
        total: payload.total,
        isFetched: {...state.isFetched, tasks: true}
      };
    case SET_LOAD_TASKS_LIST:
      return {
        ...state,
        isFetched: {...state.isFetched, tasks: payload}
      };
    case CHANGE_HANDLER:
      return {
        ...state,
        hasChanges: payload.hasChanges,
      };
    default:
      return state;
  }
};
