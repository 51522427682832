import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import { get, isEmpty, isEqual, isNull, isNil } from "lodash";
import Field from './Field';
import { translate, doNoting } from 'src/utils';

const Form = ({ setCanSave, onValidate, onChange, defaultData, config }) => {
  const getDefaultData = () => {
    return config
      .filter(({ type }) => type !== 'empty')
      .filter(({ name }) => isNil(get(defaultData, name)))
      .reduce((acc, item) => {
        const dependentField = config.filter(field => field.parentField === item.name && !isNull(defaultData[field.name]))[0];

        return { ...acc, [item.name]: (dependentField && dependentField.name) || null }
    }, defaultData)
  }

  const [formData, setFormData] = useState(getDefaultData());
  const [errors, setErrors] = useState({});

  const handleChane = event => {
    const field = event.target.name;
    const type = event.target.type;

    let value;

    switch (type) {
      case 'text':
        value = event.target.value || null;
        break;
      case 'number':
        const number = Number.parseFloat(event.target.value);
        value = Number.isNaN(number) ? '' : number;
        break;
      case 'checkbox':
        value = event.target.checked;
        break;
      case 'select':
        const num = Number.parseFloat(event.target.value)
        value = !isNull(event.target.value)
          ? Number.isNaN(num) ? event.target.value : num
          : null;
        break;
      default:
        value = event.target.value;
    }

    const data = updateData(field, value);

    setFormData(data);
    onChange(data);
    validate(data, field);
  };

  const updateData = (field, value) => {
    const dependentFields = config.filter(({ parentField }) => field === parentField);

    return dependentFields.reduce(
      (acc, { name }) => ({...acc, [name]: null }),
      { ...formData, [field]: value }
    );
  }

  const validate = (data, field) => {
    const formErrors = onValidate(data);

    if (formErrors[field]) {
      setErrors({ ...errors, [field]: formErrors[field] });
    } else {
      const updErrors = { ...errors };
      delete updErrors[field];
      setErrors(updErrors);
    }
  }

  const handleBlur = (event) => {
    const field = event.target.name;
    validate(formData, field)
  }

  useEffect(() => {
    setCanSave(!isEqual(defaultData, formData) && isEmpty(errors) && isEmpty(onValidate(formData)));
  }, [formData, errors]);

  return <>
    <Grid container spacing={3}>
      {config
        .filter(({ parentField, name }) => (parentField && formData[parentField] === name) || !parentField)
        .map((field, index) => {
        return (
          <Field
            key={index}
            {...field}
            label={field.label ? translate(field.label) : null}
            onChange={handleChane}
            onBlur={handleBlur}
            fullWidth
            size="small"
            margin="dense"
            variant="outlined"
            value={formData[field.name]}
            error={!!errors[field.name]}
            helperText={errors[field.name]}
            formData={formData}
            config={config}
            name={field.name}
          />
        )
      })}
    </Grid>
    <Box py={2} />
  </>;
};

Form.propTypes = {
  config: PropTypes.array.isRequired,
  defaultData: PropTypes.object,
  onChange: PropTypes.func,
  setCanSave: PropTypes.func,
  onValidate: PropTypes.func
};

Form.defaultProps = {
  defaultData: {},
  setCanSave: doNoting,
  onValidate: doNoting
};

export default Form;
