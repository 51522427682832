import { push, LOCATION_CHANGE } from 'connected-react-router';
import { takeLatest, put, call, select, spawn, cancel,delay } from 'redux-saga/effects';
import apiMethods from 'src/api/apiMethods';
import { setToken } from 'src/api/http-client';

import peopleSaga from 'src/views/People/saga';
import projectsSaga from 'src/views/Projects/saga';
import assetsSaga from 'src/views/Assets/saga';
import locationsSaga from 'src/views/Locations/saga';
import clientsSaga from 'src/views/Clients/saga';
import departmentsSaga from 'src/views/Departments/saga';
import moneyTypesSaga from 'src/views/MoneyTypes/saga';
import vendorsSaga from 'src/views/Vendors/saga';
import expenseCategoriesSaga from 'src/views/ExpenseCategories/saga';
import periodsSaga from 'src/views/Periods/saga';
import depreciationRulesSaga from 'src/views/DepreciationRules/saga';
import importMappingsSaga from 'src/views/ImportMappings/saga';
import periodSalariesSaga from 'src/views/PeriodSalaries/saga';
import timeSheetsSaga from 'src/views/TimeSheets/saga';
import importSaga from 'src/views/Import/saga';
import transactionsSaga from 'src/views/Transactions/saga';
import invoicesSaga from 'src/views/Invoices/saga';
import dictionariesSaga from 'src/modules/dictionaries/saga';
import * as at from './rootActions/actionTypes';
import auditLogSaga from 'src/modules/auditLog/saga';

const sagas = new Map()

function* fetchActivePeriod (props) {
  const { globalActions: { setLoading, setActivePeriod } } = props;
 yield put(setLoading(true));
  try {
    const { data } = yield call(apiMethods.get, 'periods', { filter: { active:  true} });

    yield put(setActivePeriod(data[0]))

  } catch (error) {
    // TODO: show notification
    yield delay(10000)
    yield fetchActivePeriod(props)

  } finally {
    yield put(setLoading(false));
  }
}

function* navigateToHandler({ payload: path }) {
  yield put(push(path));
}

function* pageSagaHandler(props, saga, pathname) {
  yield cancel(Array.from(sagas.values()));
  const name = yield spawn(saga, props);
  sagas.set(pathname, name);
}

function* changeLocationHandler(props, { payload }) {
  const pathname = payload.location.pathname;

  switch ('/' + pathname.split('/')[1]) {
    case '/people':
      yield call(pageSagaHandler, props, peopleSaga, pathname)
      break;
    case '/projects':
      yield call(pageSagaHandler, props, projectsSaga, pathname)
      break;
    case '/assetItems':
      yield call(pageSagaHandler, props, assetsSaga, pathname)
      break;
    case '/locations':
      yield call(pageSagaHandler, props, locationsSaga, pathname)
      break;
    case '/clients':
      yield call(pageSagaHandler, props, clientsSaga, pathname)
      break;
    case '/departments':
      yield call(pageSagaHandler, props, departmentsSaga, pathname)
      break;
    case '/moneyTypes':
      yield call(pageSagaHandler, props, moneyTypesSaga, pathname)
      break;
    case '/vendors':
      yield call(pageSagaHandler, props, vendorsSaga, pathname)
      break;
    case '/expenseCategories':
      yield call(pageSagaHandler, props, expenseCategoriesSaga, pathname)
      break;
    case '/periods':
      yield call(pageSagaHandler, props, periodsSaga, pathname)
      break;
    case '/depreciationRules':
      yield call(pageSagaHandler, props, depreciationRulesSaga, pathname)
      break;
    case '/importMappings':
      yield call(pageSagaHandler, props, importMappingsSaga, pathname)
      break;
    case '/periodSalaries':
      yield call(pageSagaHandler, props, periodSalariesSaga, pathname)
      break;
    case '/timeSheets':
      yield call(pageSagaHandler, props, timeSheetsSaga, pathname)
      break;
    case '/import':
      yield call(pageSagaHandler, props, importSaga, pathname)
      break;
    case '/transactions':
      yield call(pageSagaHandler, props, transactionsSaga, pathname)
      break;
    case '/invoices':
      yield call(pageSagaHandler, props, invoicesSaga, pathname)
      break;
    default:
      // do nothing
  }
}

export default function* rootSagaWatcher(props) {

  yield takeLatest(at.FETCH_DICTIONARIES, dictionariesSaga, props);
  yield takeLatest(at.FETCH_AUDITLOG, auditLogSaga, props);

  const token = yield localStorage.getItem('token');

  if (token) setToken(token);

  yield call(fetchActivePeriod, props);

  const router = yield select(state => state.router);

  yield call(changeLocationHandler, props, { payload: router });

  yield takeLatest(at.NAVIGATE_TO, navigateToHandler);
  yield takeLatest(LOCATION_CHANGE, changeLocationHandler, props);
}
